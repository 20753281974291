import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
	selector: 'app-logout-page',
	templateUrl: './logout-page.component.html',
	styleUrls: ['./logout-page.component.css']
})
export class LogoutPageComponent implements OnInit {

	constructor(private router: Router) {
	}

	ngOnInit() {
		if (sessionStorage.length > 0) {
			sessionStorage.clear();
		} else {
			this.router.navigate(['/login']);
		}
	}

}
