import {Component, HostListener} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from './service/login.service';
import {AuthService} from '@wame/adfs-angular';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent {
	hamMenu: boolean;
	showComponent: boolean;
	loggedInUser: string;
	loggedInUserMarketText: string;
	year: number;
	displayVersion = '13.0';

	constructor(private translate: TranslateService, private loginService: LoginService, private authService: AuthService) {
		// loginService.getAuthorisationToken();
		loginService.isLoggedIn$().subscribe(isLoggedIn => {
			if (isLoggedIn) {
				this.showComponent = isLoggedIn;
				this.loggedInUser = sessionStorage.getItem('cdsId');
				this.year = new Date().getFullYear();
				this.loggedInUserMarketText = sessionStorage.getItem('loggedInUserMarketText');
				translate.use(sessionStorage.getItem('loggedInUserLanguageCode'));
			}
		});
	if (sessionStorage.getItem('cdsId') && sessionStorage.getItem('loggedInUserMarketText') && sessionStorage.getItem('loggedInUserLanguageCode')) {
    this.loggedInUser = sessionStorage.getItem('cdsId');
    this.loggedInUserMarketText = sessionStorage.getItem('loggedInUserMarketText');
    translate.use(sessionStorage.getItem('loggedInUserLanguageCode'));
}
	}
	

	@HostListener('window:resize', ['$event']) onResize(event?) {
		if (event.target.innerWidth > 767) {
			this.hamMenu = false;
		}
	}

	public logoutEvent() {
		this.showComponent = false;
		this.authService.logout();
	}
}
