import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from './service/user.service';
import {environment} from '../environments/environment';
import {HttpErrorInterceptor} from 'src/app/service/http-error.interceptor';
import * as  datamarketLanguageMapping from 'src/assets/data/market-language-mapping.json';
import * as  switzerlandLocaleMapping from 'src/assets/data/switzerland-i18n.json';
import {WholesaleAPIAuthService} from './service/wholesale-commitment-api-auth.service';
import {LoginService} from './service/login.service';
import {WholesaleConstants} from './constants/wholesale.constants';

/*
This component is called by the UAA when following the redirect URL post authentication
It pulls the tokens from the web fragment ( ?#access_token=...&id_token=... ) and stores them in local storage
 */
@Component({
	template: `
		<h3>Redirecting to requested URL</h3>
	`,
	styles: ['h3 { text-align: center; display: grid; place-items: center; height: 100%;}']
})
export class OAuthCallbackComponent implements OnInit {
	private userService: UserService;
	private switzerlandSelectLanguage: any[] = (switzerlandLocaleMapping as any).default;
	private marketLanguageMapping: any[] = (datamarketLanguageMapping as any).default;
	private marketInfo: MarketInfoModel;
	public isServerError: boolean = false;
	public errorMsg: string;
	public authToken: string;

	constructor(private _router: Router, private httpInterceptorService: HttpErrorInterceptor,
				private wholesaleAPIAuthService: WholesaleAPIAuthService, private loginService: LoginService) {
	}

	ngOnInit() {
		// Parse paramters and store tokens in local storage
		// this.switzerlandSelectLanguage = require('assets/data/switzerland-i18n.json');
		// this.marketLanguageMapping = require('assets/data/market-language-mapping.json');
		if (document.URL.indexOf('&') === -1) {
			location.reload();
		}
		let  callbackResponse = new Map();
		callbackResponse.set(0,document.URL.split('#')[1]);
		const responseParameters = callbackResponse.get(0).split('&');
		   const parameterMap = new Map<string, string>();
			for (let i = 0; i < responseParameters.length; i++) {
				const[key, value] = responseParameters[i].split('=');
				if(parameterMap.has(key)) {
					parameterMap.set(key,value);
				}else{
					parameterMap.set(key,value);
				}
			}
			if (
				parameterMap.get('access_token') != null &&
				parameterMap.get('id_token') != null
			) {
				this.createSessionVariables(parameterMap).then(() => {
					this.loginService.isLoggedIn.next(true);
					this._router.navigate(['/home-page']);
				}).catch(err => {
					console.log(err);
				});
			}
	}


	private async createSessionVariables(parameterMap: Map<string, string>) {
		const expiration = Number(parameterMap.get('expires_in')) + (Math.trunc(new Date().getTime() / 1000));
		const originalAccessToken = parameterMap.get('access_token');
		const access_token = atob(parameterMap.get('access_token').split('.')[1]);
		const jsonToken = JSON.parse(access_token);
		// const idToken = JSON.parse(atob(parameterMap['id_token'].split('.')[1]));
		const url = window.location.href;
		let loggedInUserMarketCode = '';
		let loggedInUserMarketText = '';
		let loggedInUserLanguageCode = '';
		let siteCode = '';

		this.marketInfo = this.marketLanguageMapping.filter(market => market.dealerMarket === jsonToken.c)[0].marketInfo
		loggedInUserMarketCode = this.marketInfo.marketCode;
		loggedInUserMarketText = this.marketInfo.marketText;

		sessionStorage.setItem('encodedIdToken', access_token);
		sessionStorage.setItem('encodedAccessToken', parameterMap.get('access_token'));
		sessionStorage.setItem('authToken', parameterMap.get('access_token'));
		sessionStorage.setItem('tokenExp', String(expiration));
		sessionStorage.setItem('cdsId', jsonToken.CommonName.toUpperCase());
		sessionStorage.setItem('loggedInUserMarketCode', loggedInUserMarketCode);
		sessionStorage.setItem('loggedInUserMarketText', loggedInUserMarketText);
		sessionStorage.setItem('loggedInSiteCode', jsonToken.fordSiteCode.substring(3, 8));

		if (url.indexOf('dealerconnection.com') > 0) {
			sessionStorage.setItem('baseCommitmentUrl', environment.baseCommitmentUrl_e);
			sessionStorage.setItem('baseProductionOfferUrl', environment.baseProductionOfferUrl_e);
			sessionStorage.setItem('baseAllocationUrl', environment.baseWholesaleAllocationUrl_e);
			sessionStorage.setItem('baseDealerServiceUrl', environment.baseDealerService_e);
			sessionStorage.setItem('baseBulkOrderCreationUrl', environment.baseBulkOrderCreationUrl_e);
		} else {
			sessionStorage.setItem('baseCommitmentUrl', environment.baseCommitmentUrl_i);
			sessionStorage.setItem('baseProductionOfferUrl', environment.baseProductionOfferUrl_i);
			sessionStorage.setItem('baseAllocationUrl', environment.baseWholesaleAllocationUrl_i);
			sessionStorage.setItem('baseDealerServiceUrl', environment.baseDealerServiceUrl_i);
			sessionStorage.setItem('baseBulkOrderCreationUrl', environment.baseBulkOrderCreationUrl_i);
		}

		// const gotdGWSRoles = jsonToken.memberof.filter(role => role.includes('GOTDGA-Roles'));
		const getRolesFromApsUrl = sessionStorage.getItem('baseDealerServiceUrl') + WholesaleConstants.getApsRolesUrl;
		const roles: string[] = [];
		await this.loginService.getApsRoles(getRolesFromApsUrl).then(response => {
			response['RoleAssignment'].map(role => roles.push(role.RoleName));
		}).catch(err => {
			console.log('Failed to retrieve Role ' + err);
		});

		// for (let i = 0; i < gotdGWSRoles.length; i++) {
		// 	if (gotdGWSRoles[i].includes('GOTDGA-Roles-Zone Manager') || gotdGWSRoles[i].includes('GOTDGA-Roles-NSC')) {
		// 		sessionStorage.setItem('roleCode', '1');
		// 		break;
		// 	} else if (gotdGWSRoles[i].includes('GOTDGA-Roles-CMA Dealer')){
		// 		sessionStorage.setItem('roleCode', '2');
		// 		break;
		// 	} else if (gotdGWSRoles[i].includes('GOTDGA-Roles-Dealer Group')) {
		// 		sessionStorage.setItem('roleCode', '3');
		// 		break;
		// 	} else if (gotdGWSRoles[i].includes('GOTDGA-Roles-Product Team')) {
		// 		sessionStorage.setItem('roleCode', '4');
		// 		break;
		// 	} else if (gotdGWSRoles[i].includes('GOTDGA-Roles-DemoUser')) {
		// 		sessionStorage.setItem('roleCode', '5');
		// 		break;
		// 	}
		// }


		if (roles.includes('Zone Manager') || roles.includes('NSC')) {
			sessionStorage.setItem('roleCode', '1');
		} else if (roles.includes('Dealer Group')) {
			sessionStorage.setItem('roleCode', '3');
		} else if (roles.includes('CMA Dealer')) {
			sessionStorage.setItem('roleCode', '2');
		} else if (roles.includes('Product Team')) {
			sessionStorage.setItem('roleCode', '4');
		} else if (roles.includes('DemoUser')) {
			sessionStorage.setItem('roleCode', '5');
		}

		if (sessionStorage.getItem('roleCode') !== '2' && sessionStorage.getItem('roleCode') !== '3') {
			loggedInUserLanguageCode = 'en_GB';
		} else if (environment.production == true && jsonToken.c === 'FRA' && jsonToken.preferredLanguage !== undefined) {
			loggedInUserLanguageCode = jsonToken.preferredLanguage + '_' + jsonToken.preferredLanguage.toUpperCase();
		} else if (environment.production == true && jsonToken.c === 'CHE' && jsonToken.preferredLanguage !== undefined) {
			if (jsonToken.preferredLanguage === 'de') {
				loggedInUserLanguageCode = jsonToken.preferredLanguage + '_' + jsonToken.c.toUpperCase().substring(0, 2);
			} else {
				loggedInUserLanguageCode = 'fr_' + jsonToken.c.toUpperCase().substring(0, 2);
			}
		} else {
			loggedInUserLanguageCode = this.marketInfo.locale;
		}
		sessionStorage.setItem('loggedInUserLanguageCode', loggedInUserLanguageCode);
	}

	private validateSwitzerlandDealerId(idToken: any): any[] {
		return this.switzerlandSelectLanguage.filter(dealer => dealer.dealerId === idToken.fordSiteCode.match(/\d+/g).toString());
	}

	private registerErrorHandler() {
		this.httpInterceptorService.errorStream$().subscribe(errorObj => {
			this.isServerError = true;
			if (errorObj.error.error) {
				this.errorMsg = errorObj.error.error;
			} else {
				this.errorMsg = errorObj.statusText;
			}

		});
	}

	/*private getAuthToken() {
		this.wholesaleAPIAuthService.getAuthorisationData().subscribe(
			(response: Authorisation) => {
				this.authToken = response.access_token;
				sessionStorage.setItem('authToken', this.authToken);
			}
		);
	}*/

}

interface MarketInfoModel {
	marketCode: string;
	marketText: string;
	locale: string;
}
