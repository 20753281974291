import {Component, OnInit} from '@angular/core';
import {WholesaleService} from '../service/wholesale.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {WholesaleResponse} from '../models/wholesale-response.model';
import {formatDate} from '@angular/common';
import {UserService} from '../service/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {WholesaleConstants} from '../constants/wholesale.constants';
import {WholesaleAPIAuthService} from '../service/wholesale-commitment-api-auth.service';
import {Authorisation} from '../models/authorisation.model';
import {WholesaleMessageService} from '../service/wholesale-message.service';
import {HttpErrorInterceptor} from '../service/http-error.interceptor';
import {ProductionOfferResponse} from '../models/production-offer-response.model';
import {TranslateService} from '@ngx-translate/core';

import * as  zoneMangerEmailListProd from 'src/assets/data/zonemanager-mailid-prod.json';
import * as  zoneMangerEmailListPreProd from 'src/assets/data/zonemanager-mailid-preprod.json';
import {FormGroup} from '@angular/forms';
import {DealerResponse} from '../models/dealer-details';
import {environment} from '../../environments/environment';
import {DistrictModel} from '../models/district-model';
import { error } from 'console';

@Component({
	selector: 'wholesale-commitment-by-zone',
	templateUrl: './wholesale-commitment-by-zone.component.html',
	styleUrls: ['./wholesale-commitment-by-zone.component.css']
})
export class WholesaleCommitmentByZoneComponent implements OnInit {

	public isAuthorizedUser = false;
	public formWholesale: FormGroup;
	public unauthorizationForm: FormGroup;
	public wholesaleCommitmentResponseList: any[] = [];
	public zoneDropdownList: any[];
	public districtDropdownList: any[];
	public dealerGroupList: any[] = [];
	public dealerIdList: any[] = [];
	public buttonLabel: any;
	public selectedDistrict: any;
	public selectedZone: any;
	public selectedDealerGroup: any;
	public selectedDealerId: any;
	public isNoRecordFound = false;
	public wholesaleCurrentMonth: any;
	public systemMonth: any;
	public commitStatus: string;
	public commitButtonDisable = false;
	public showCommitButton = false;
	public showWholesaleDealerDetailTable = false;
	public isWholesaleStatusOpen: boolean;
	public isServerError: boolean = false;
	public errorMsg: string;
	public isZoneManager: boolean = false;
	public isFobMarket: boolean = false;
	public isFxMarket: boolean = false;
	public showVehiclesTable: boolean = false;
	public isApproved: boolean = null;

	public isCopyAllChecked: boolean;
	public isDealerSelection = false;
	public isWholesaleCommitmentServiceDown = false;
	public roleCode;
	public authToken: string;
	public finalWholesaleCommitmentList: WholesaleResponse[];
	public initialWholesaleViewList: WholesaleResponse[];
	public wholesaleResBackupForNoChangesDetected: any;
	public toZoneManagerEmailId: string;
	public zoneMangerEmailList: any[];
	public wholesaleCurrentMonthAndYear: any;

	constructor(private router: Router,
				private spinner: NgxSpinnerService,
				private wholesaleService: WholesaleService,
				private userService: UserService,
				private wholesaleAPIAuthService: WholesaleAPIAuthService,
				private wholesaleMessageService: WholesaleMessageService,
				private httpInterceptorService: HttpErrorInterceptor,
				private translate: TranslateService) {
	}

	ngOnInit() {
		// this.getAuthToken();
		this.isZoneManager = this.userService.canViewZoneCommitment();
		this.isAuthorizedUser = this.userService.isAuthorisedUser();
		this.wholesaleCurrentMonth = this.transformDate(new Date(), WholesaleConstants.dateFormatMMMYYYY);
		this.wholesaleCurrentMonthAndYear = this.transformDate(new Date().setMonth(new Date().getMonth()), WholesaleConstants.dateFormatYYYYMM);
		this.systemMonth = new Date().toLocaleString('en-us', {month: 'long'});
		this.registerErrorHandler();
		this.roleCode = sessionStorage.getItem('roleCode');
		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WAEGB') {
			this.isFobMarket = true;
			this.wholesaleService.getDealerGroupList().subscribe((response: any[]) => response.map(dealerGroupName => {
				this.dealerGroupList.push({label: dealerGroupName, value: dealerGroupName});
			}));
		} else if (sessionStorage.getItem('loggedInUserMarketCode') !== 'WAEGB') {
			this.isFxMarket = true;
			this.wholesaleService.getDealerIdList().subscribe((response: any[]) => response['dealerDetails'].map(dealerDetail => {
				this.dealerIdList.push({
					label: dealerDetail.dealerId + '--' + dealerDetail.dealerName,
					value: dealerDetail.dealerId
				})
			}));
		}

		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WAEFX') {
			if (environment.production === true) {
				this.zoneMangerEmailList = (zoneMangerEmailListProd as any).default;
			} else {
				this.zoneMangerEmailList = (zoneMangerEmailListPreProd as any).default;
			}
		}
		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WSPA2') {
			this.buttonLabel = 'Approve';
			this.isApproved = true;
		} else {
			this.buttonLabel = 'Commit';
		}
		this.getDistrictAndZonesList();
	}

	public onDistrictSelect(selectedDistrict: DistrictModel) {
		this.spinner.show();
		this.showWholesaleDealerDetailTable = false;
		this.showVehiclesTable = false;
		this.selectedDealerGroup = '';
		this.selectedDealerId = '';
		this.zoneDropdownList = [];
		this.selectedZone = '';
		this.selectedDealerGroup = '';
		this.showCommitButton = false;
		this.zoneDropdownList = this.districtDropdownList.filter(district => district.districtCode === selectedDistrict.districtCode)[0].zones;
		this.sortWholesaleCommitmentList(this.zoneDropdownList, 'zoneCode');
		this.spinner.hide();
	}

	public onZoneSelect() {
		this.wholesaleService.getWholesaleStatus().subscribe(response => {
			this.isWholesaleStatusOpen = response.wholesaleStatus;
			this.spinner.show();
			this.showWholesaleDealerDetailTable = false;
			this.wholesaleCommitmentResponseList = [];
			this.isCopyAllChecked = false;
			this.isDealerSelection = false;
			this.getWholesaleDealerDetailByZone();
		});
	}

	public onDealerGroupSelect() {
		this.wholesaleService.getWholesaleStatus().subscribe(response => {
			this.isWholesaleStatusOpen = response.wholesaleStatus;
			this.spinner.show();
			this.zoneDropdownList = [];
			this.isCopyAllChecked = false;
			this.selectedZone = '';
			this.selectedDistrict = '';
			this.showWholesaleDealerDetailTable = false;
			this.isDealerSelection = false;
			this.wholesaleCommitmentResponseList = [];
			this.getWholesaleDealerDetailByDealerGroup();
		});
	}

	public onDealerIdSelect() {
		this.wholesaleService.getWholesaleStatus().subscribe(response => {
			this.isWholesaleStatusOpen = response.wholesaleStatus;
			this.spinner.show();
			this.selectedZone = '';
			this.selectedDistrict = '';
			this.showVehiclesTable = false;
			this.showWholesaleDealerDetailTable = false;
			this.isDealerSelection = true;
			this.wholesaleCommitmentResponseList = [];
			this.getWholesaleDealerDetailByDealerId();
		});
	}

	onCommit() {
		this.spinner.show();
		window.scrollTo(0, 0);
		const updatedWholesaleCommitmentList: WholesaleResponse[] = [];
		this.initialWholesaleViewList = JSON.parse(this.wholesaleResBackupForNoChangesDetected);
		this.finalWholesaleCommitmentList = JSON.parse(JSON.stringify(this.wholesaleCommitmentResponseList));
		this.sortWholesaleCommitmentList(this.initialWholesaleViewList, 'dealerId');
		this.sortWholesaleCommitmentList(this.finalWholesaleCommitmentList, 'dealerId');
		for (let i = 0; i < this.finalWholesaleCommitmentList.length; i++) {
			if (sessionStorage.getItem('loggedInUserMarketCode') !== 'WSPA2') {
				if (this.finalWholesaleCommitmentList[i].allocationVehicleVolumeCommitCount !== this.initialWholesaleViewList[i].allocationVehicleVolumeCommitCount) {
					updatedWholesaleCommitmentList.push(this.finalWholesaleCommitmentList[i]);
				}
			} else if (this.finalWholesaleCommitmentList[i].allocationVehicleVolumeCommitCount !== this.initialWholesaleViewList[i].allocationVehicleVolumeCommitCount ||
				(this.finalWholesaleCommitmentList[i].isApproved !== null && !this.finalWholesaleCommitmentList[i].isApproved)) {
				updatedWholesaleCommitmentList.push(this.finalWholesaleCommitmentList[i]);
			}
		}
		let saveWholesaleCommitmentCounter = 0;
		if (updatedWholesaleCommitmentList.length > 0) {
			updatedWholesaleCommitmentList.forEach(wholesaleRequest => {
				this.wholesaleService.saveWholesaleCommitment(sessionStorage.getItem('authToken'),
					wholesaleRequest.allocationVehicleVolumeCommitCount, wholesaleRequest.dealerId
					, wholesaleRequest.productionMonth.toString(), wholesaleRequest.modelYear.toString(),
					wholesaleRequest.regionalAllocationVLCode, wholesaleRequest.ptvlCode,
					this.wholesaleCurrentMonthAndYear, this.isApproved)
					.subscribe(wholesaleRespData => {
						if (++saveWholesaleCommitmentCounter === updatedWholesaleCommitmentList.length) {
							this.getWholesaleResponseViewBackupForValidation();

							if (updatedWholesaleCommitmentList[0].specificationMarketCode === 'WAEFX' && this.isDealerSelection === true) {
								this.wholesaleService.sendEmailMesaageToZoneManager(this.toZoneManagerEmailId, this.finalWholesaleCommitmentList)
									.subscribe();
							}
							this.spinner.hide();
							this.wholesaleMessageService.showMesssge(this.translate.instant(WholesaleConstants.commitSuccessMessage), WholesaleConstants.success);
						}
					}, (err: HttpErrorResponse) => {
						if (++saveWholesaleCommitmentCounter === updatedWholesaleCommitmentList.length) {
							this.spinner.hide();
							this.wholesaleMessageService.showMesssge(WholesaleConstants.commitFailureMessage, WholesaleConstants.error);
						}
					});
			});
		} else {
			this.spinner.hide();
			this.wholesaleMessageService.showMesssge(this.translate.instant(WholesaleConstants.noChangesMessage), WholesaleConstants.warn);
		}
	}

	public transformDate(date: string | number | Date, pattern: string) {
		return formatDate(date, pattern, 'en-US');
	}

	toggleCommitButton(isWholesaleStatus: boolean) {
		if (this.roleCode === '4') {
			this.commitButtonDisable = true;
			this.commitStatus = WholesaleConstants.commitStatusRoleBasedMsg;
		} else if (!isWholesaleStatus) {
			this.commitButtonDisable = true;
			this.commitStatus = this.translate.instant(WholesaleConstants.commitStatusMsg);
		} else {
			this.commitStatus = '';
			this.commitButtonDisable = false;
		}
	}

	public getDistrictAndZonesList() {
		this.wholesaleService.getDistrictAndZoneList().subscribe((response: any[]) => {
			this.districtDropdownList = response;
			this.sortWholesaleCommitmentList(this.districtDropdownList, 'districtCode');
		});
	}

	public setVehicleLineNameAndProdMonthInWholesaleResponse(wholesaleDetail: WholesaleResponse[]) {
		wholesaleDetail.forEach(wholesaleRecord => {
			wholesaleRecord.productionMonthAndYear = this.transformDate(wholesaleRecord.productionMonth.toString().substr(0, 4) + '-' + wholesaleRecord.productionMonth.toString().substr(4), WholesaleConstants.dateFormatMMMYYYY);
			if (wholesaleRecord.vehicleLineAndRegionalVehicleLineCode === 'GCR') {
				wholesaleRecord.vehicleLineName = 'P375 Ranger ICA ';
			}
			this.wholesaleCommitmentResponseList.push(wholesaleRecord);
		});

		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WAEFX') {
			this.mappingDealerZoneManagerMailId(this.wholesaleCommitmentResponseList);
		}
	}

	/*public getAuthToken() {
		this.wholesaleAPIAuthService.getAuthorisationData().subscribe(
			(response: Authorisation) => {
				this.authToken = response.access_token;
				sessionStorage.setItem('authToken', this.authToken);
			}
		);
	}*/

	public getWholesaleDealerDetailByDealerGroup() {
		this.wholesaleService.getDealerDetails(null, null, this.selectedDealerGroup, null).subscribe((
			dealerResponse: DealerResponse[]) => {
			this.wholesaleService.getProductionOffersFromDealerRequest(dealerResponse, this.wholesaleCurrentMonthAndYear)
				.subscribe((response: ProductionOfferResponse[]) => {
					this.wholesaleService.getWholesaleDetail(response, this.wholesaleCurrentMonthAndYear)
						.subscribe((response: WholesaleResponse[]) => {
							this.setWholesaleDealerDetail(response);
						},error => this.registerErrorHandler());
				});
		});
	}

	public getWholesaleDealerDetailByDealerId() {
		this.wholesaleService.getDealerDetails(null, null, null, this.selectedDealerId).subscribe((
			dealerResponse: DealerResponse[]) => {
			this.wholesaleService.getProductionOffersFromDealerRequest(dealerResponse, this.wholesaleCurrentMonthAndYear)
				.subscribe((response: ProductionOfferResponse[]) => {
					this.wholesaleService.getWholesaleDetail(response, this.wholesaleCurrentMonthAndYear)
						.subscribe((response: WholesaleResponse[]) => {
							this.setWholesaleDealerDetailByDealerId(response);
						},error => this.registerErrorHandler());
				});
		});
	}

	public setWholesaleDealerDetail(response: WholesaleResponse[]) {
		if (response.length > 0) {
			this.setVehicleLineNameAndProdMonthInWholesaleResponse(response);
			this.wholesaleResBackupForNoChangesDetected = JSON.stringify(response);
			this.showWholesaleDealerDetailTable = true;
			this.showCommitButton = true;
			this.isWholesaleCommitmentServiceDown = false;
			this.isNoRecordFound = false;
			this.isServerError = false;
		} else {
			this.isNoRecordFound = true;
		}
		this.toggleCommitButton(this.isWholesaleStatusOpen);
		this.spinner.hide();
	}

	public getWholesaleDealerDetailByZone() {
		this.wholesaleService.getDealerDetails(this.selectedDistrict.districtCode, this.selectedZone.zoneCode, null, null).subscribe((
			dealerResponse: DealerResponse[]) => {
			this.wholesaleService.getProductionOffersFromDealerRequest(dealerResponse, this.wholesaleCurrentMonthAndYear)
				.subscribe((response: ProductionOfferResponse[]) => {
					this.wholesaleService.getWholesaleDetail(response, this.wholesaleCurrentMonthAndYear)
						.subscribe((respon: WholesaleResponse[]) => {
							this.setWholesaleDealerDetail(respon);
						},error => this.registerErrorHandler());
				});
		});
	}

	public setWholesaleDealerDetailByDealerId(response: WholesaleResponse[]) {
		if (response.length > 0) {
			this.setVehicleLineNameAndProdMonthInWholesaleResponse(response);
			this.wholesaleResBackupForNoChangesDetected = JSON.stringify(response);
			this.showVehiclesTable = true;
			this.showCommitButton = true;
			this.isWholesaleCommitmentServiceDown = false;
			this.isNoRecordFound = false;
			this.isServerError = false;
		} else {
			this.isNoRecordFound = true;
		}
		this.toggleCommitButton(this.isWholesaleStatusOpen);
		this.spinner.hide();
	}

	public getWholesaleResponseViewBackupForValidation() {
		this.wholesaleResBackupForNoChangesDetected = JSON.stringify(this.wholesaleCommitmentResponseList);
	}

	public mappingDealerZoneManagerMailId(wholesaleList: WholesaleResponse[]) {
		this.toZoneManagerEmailId = this.zoneMangerEmailList.filter(zoneId => zoneId.zone === wholesaleList[0].dealerZoneCode)[0].emailId;
	}

	public sortWholesaleCommitmentList(commitmentList: WholesaleResponse[], sortBy: string) {
		return commitmentList.sort((n1, n2) => {
			if (n1[sortBy] > n2[sortBy]) {
				return 1;
			}
			if (n1[sortBy] < n2[sortBy]) {
				return -1;
			}
			return 0;
		});
	}

	public registerErrorHandler() {
		this.httpInterceptorService.errorStream$().subscribe(errorObj => {
			const url: string = errorObj.url;
			if (!url.includes('WholesaleCommitmentService')) {
				const response = this.isJson(errorObj.error) ? JSON.parse(errorObj.error) : errorObj.error;
				this.isServerError = true;
				let errMessage;
				if (response instanceof ArrayBuffer) {
					errMessage = response;
				} else if (this.isJson(response.error.messages[0])) {
					errMessage = JSON.parse(response.error.messages[0]);
				} else {
					errMessage = response.error.messages[0];
				}

				if (errMessage.error) {
					this.errorMsg = errMessage.error;
				} else {
					this.errorMsg = errorObj.statusText;
				}
			} else {
				this.isServerError = true;
				if (errorObj.error.error) {
					this.errorMsg = errorObj.error.error;
				} else {
					this.errorMsg = errorObj.statusText;
				}
			}
			this.spinner.hide();
		});
	}

	private isJson(req): boolean {
		try {
			JSON.parse(req);
			return true;
		} catch (err) {
			return false;
		}
	}
}
