import {Component, OnInit} from '@angular/core';
import {WholesaleService} from '../service/wholesale.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {formatDate} from '@angular/common';
import {UserService} from '../service/user.service';
import {WholesaleConstants} from '../constants/wholesale.constants';
import {WholesaleAPIAuthService} from '../service/wholesale-commitment-api-auth.service';
import {Authorisation} from '../models/authorisation.model';
import {WholesaleMessageService} from '../service/wholesale-message.service';
import {WholesaleResponse} from '../models/wholesale-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpErrorInterceptor} from '../service/http-error.interceptor';
import {ProductionOfferResponse} from '../models/production-offer-response.model';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup} from '@angular/forms';
import {DealerResponse} from '../models/dealer-details';

import * as  zoneMangerEmailListProd from 'src/assets/data/zonemanager-mailid-prod.json';
import * as  zoneMangerEmailListPreProd from 'src/assets/data/zonemanager-mailid-preprod.json';
import * as  deliverViewListJson from 'src/assets/data/dealerViewList.json';
import {environment} from '../../environments/environment';

@Component({
	selector: 'app-wholesale-dealer-commitment',
	templateUrl: './wholesale-dealer-commitment.component.html',
	styleUrls: ['./wholesale-dealer-commitment.component.css']
})

export class WholesaleDealerCommitmentComponent implements OnInit {
	public formWholesale: FormGroup;
	public wholesaleCommitmentResponseList;
	public initialWholesaleViewList: WholesaleResponse[];
	public isAuthorizedUser = false;
	public wholesaleCurrentMonth: any;
	public systemMonth: any;
	public dealerId: string;
	public dealerName: string;
	public showVehiclesTable = false;
	public wholesaleStatus = false;
	public commitButtonDisable = false;
	public commitStatus: string;
	public commitButtonShow = false;
	public isNoRecordFound = false;
	public isServerError = false;
	public showDealerDetailDiv = false;
	public errorMsg: string;
	public isDealerGroupDetailDiv = false;
	public showVehiclesTreeTable = false;
	public isDealer = false;
	public isDealerGroup = false;
	public siteCode: string;
	public isConfirmMessage = false;
	public isApproved: boolean = null;
	public authToken: string;
	public finalWholesaleViewList: any;
	public zoneMangerEmailList: any[];
	public toZoneManagerEmailId: string;
	public wholesaleCurrentMonthAndYear: any;
	public wholesaleDealerViewData: string;
	public dealerViewList: any[];
	public wholesaleStatusMonth: string;
	public isStatus: boolean;

	constructor(private router: Router,
				private spinner: NgxSpinnerService,
				private wholesaleService: WholesaleService,
				private userService: UserService,
				private wholesaleAPIAuthService: WholesaleAPIAuthService,
				private wholesaleMessageService: WholesaleMessageService,
				private httpInterceptorService: HttpErrorInterceptor,
				private translate: TranslateService) {
	}

	ngOnInit() {
		this.spinner.show();
		// this.getAuthToken();
		this.isDealer = this.userService.canViewDealerCommitment();
		this.isDealerGroup = this.userService.canViewDealerGroupCommitment();
		this.isAuthorizedUser = this.userService.isAuthorisedUser();
		this.dealerViewList = (deliverViewListJson as any).default;
		this.wholesaleCommitmentResponseList = [];
		this.registerErrorHandler();
		this.wholesaleCurrentMonth = this.transformDate(new Date(), WholesaleConstants.dateFormatMMMMYYYY);
		this.wholesaleCurrentMonthAndYear = this.transformDate(new Date().setMonth(new Date().getMonth()), WholesaleConstants.dateFormatYYYYMM);
		this.systemMonth = new Date().toLocaleString('en-us', {month: 'long'});
		this.showVehiclesTable = false;
		this.showVehiclesTreeTable = false;
		this.isConfirmMessage = false;
		this.siteCode = sessionStorage.getItem('loggedInSiteCode');

		if (!this.isDealerGroup) {
			this.getWholesaleDealerDetail(this.siteCode);

		} else {
			this.getWholesaleDealerDetailByDealerGroup(this.siteCode);
		}
		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WAEFX') {
			if (environment.production === true) {
				this.zoneMangerEmailList = (zoneMangerEmailListProd as any).default;
			} else {
				this.zoneMangerEmailList = (zoneMangerEmailListPreProd as any).default;
			}
			this.isConfirmMessage = true;
		}
		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WAECH') {
			this.isConfirmMessage = true;
		}
		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WSPA2') {
			this.isApproved = false;
		}
	}

	onDealerCommit() {
		this.spinner.show();
		const updatedWholesaleCommitmentList: WholesaleResponse[] = [];
		this.initialWholesaleViewList = JSON.parse(this.wholesaleDealerViewData);
		this.finalWholesaleViewList = JSON.parse(JSON.stringify(this.wholesaleCommitmentResponseList));
		this.sortWholesaleCommitmentList(this.initialWholesaleViewList, 'dealerId');
		this.sortWholesaleCommitmentList(this.finalWholesaleViewList, 'dealerId');
		this.compareInitialAndFinalWholesale(updatedWholesaleCommitmentList);
		let commitWholesaleCounter = 0;
		if (updatedWholesaleCommitmentList.length > 0) {
			updatedWholesaleCommitmentList.forEach(wholesaleRequest => {
				this.wholesaleService.saveWholesaleCommitment(this.authToken, wholesaleRequest.allocationVehicleVolumeCommitCount, wholesaleRequest.dealerId,
					wholesaleRequest.productionMonth.toString(), wholesaleRequest.modelYear.toString(), wholesaleRequest.regionalAllocationVLCode, wholesaleRequest.ptvlCode, this.wholesaleCurrentMonthAndYear, this.isApproved)
					.subscribe(() => {
						if (++commitWholesaleCounter === updatedWholesaleCommitmentList.length) {
							this.getWholesaleViewDataBackupForValidation();
							if (updatedWholesaleCommitmentList[0].specificationMarketCode === 'WAEFX') {
								this.wholesaleService.sendEmailMesaageToZoneManager(this.toZoneManagerEmailId, this.finalWholesaleViewList)
									.subscribe();
							}

							this.spinner.hide();
							this.wholesaleMessageService.showMesssge(this.translate.instant(WholesaleConstants.commitSuccessMessage), WholesaleConstants.success);
						}
					}, (err: HttpErrorResponse) => {
						console.log(err.message);
						if (++commitWholesaleCounter === updatedWholesaleCommitmentList.length) {
							this.spinner.hide();
							this.wholesaleMessageService.showMesssge(this.translate.instant(WholesaleConstants.commitFailureMessage), WholesaleConstants.error);
						}
					});
			});
		} else {
			this.spinner.hide();
			this.wholesaleMessageService.showMesssge(this.translate.instant(WholesaleConstants.noChangesMessage), WholesaleConstants.warn);
		}
	}

	getWholesaleViewDataBackupForValidation() {
		this.wholesaleDealerViewData = JSON.stringify(this.wholesaleCommitmentResponseList);
	}

	public transformDate(date: string | number | Date, pattern: string) {
		return formatDate(date, pattern, 'en-US');
	}

	public toggleCommitButton(isWholesaleStatus: boolean) {
		if (!isWholesaleStatus) {
			this.commitButtonDisable = true;
		} else {

			this.commitButtonDisable = false;
		}
	}

	updateAmendedAllocation(wholesaleList: WholesaleResponse[]) {
		wholesaleList.forEach(wholesaleDetail => {
			wholesaleDetail.productionMonthAndYear =
				this.transformDate(wholesaleDetail.productionMonth.toString()
					.substr(0, 4) + '-' + wholesaleDetail.productionMonth.toString().substr(4),
					WholesaleConstants.dateFormatMMMYYYY);
			if (wholesaleDetail.vehicleLineAndRegionalVehicleLineCode === 'GCR') {
				wholesaleDetail.vehicleLineName = 'P375 Ranger ICA ';
			}
			this.wholesaleCommitmentResponseList.push(wholesaleDetail);
		});

		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WAEFX') {
			this.mappingDealerZoneManagerMailId(this.wholesaleCommitmentResponseList);
		}

	}

	/*public getAuthToken() {
		this.wholesaleAPIAuthService.getAuthorisationData().subscribe(
			(response: Authorisation) => {
				this.authToken = response.access_token;
				sessionStorage.setItem('authToken', this.authToken);
			}
		);
	}*/

	public getWholesaleDealerDetail(dealerId: string) {
		this.wholesaleService.getDealerDetails(null, null, null, dealerId).subscribe((
			dealerResponse: DealerResponse[]) => {
			this.wholesaleService.getProductionOffersFromDealerRequest(dealerResponse, this.wholesaleCurrentMonthAndYear)
				.subscribe((response: ProductionOfferResponse[]) => {
					this.wholesaleService.getWholesaleDetail(response, this.wholesaleCurrentMonthAndYear).subscribe((wholesaleResponse: WholesaleResponse[]) => {
						if (wholesaleResponse.length > 0) {
							this.commitButtonShow = true;
							this.showVehiclesTable = true;
							this.wholesaleCommitmentResponseList = [];
							this.updateAmendedAllocation(wholesaleResponse);
							this.wholesaleDealerViewData = JSON.stringify(wholesaleResponse);
							this.dealerId = this.wholesaleCommitmentResponseList[0].dealerId;
							this.dealerName = this.wholesaleCommitmentResponseList[0].dealerName;
							//get wholesale status
							this.getWholesaleStatus();
						} else {
							this.dealerId = dealerId;
							this.dealerName = '-';
							this.isNoRecordFound = true;

						}
						this.toggleCommitButton(this.wholesaleStatus);
						this.spinner.hide();
						this.showDealerDetailDiv = true;
						this.isDealerGroupDetailDiv = false;

					});
				});
		});
	}

	public getWholesaleDealerDetailByDealerGroup(dealerGroupName: string) {
		this.wholesaleService.getDealerDetails(null, null, dealerGroupName, null).subscribe((
			dealerResponse: DealerResponse[]) => {
			this.wholesaleService.getProductionOffersFromDealerRequest(dealerResponse, this.wholesaleCurrentMonthAndYear)
				.subscribe((response: ProductionOfferResponse[]) => {
					this.wholesaleService.getWholesaleDetail(response, this.wholesaleCurrentMonthAndYear).subscribe((wholesaleResponse: WholesaleResponse[]) => {
						if (wholesaleResponse.length > 0) {
							this.commitButtonShow = true;
							this.showVehiclesTreeTable = true;
							this.wholesaleCommitmentResponseList = [];
							this.updateAmendedAllocation(wholesaleResponse);
							this.wholesaleDealerViewData = JSON.stringify(wholesaleResponse);
							this.dealerId = '';
							this.dealerName = this.wholesaleCommitmentResponseList[0].dealerGroupName;
							//get wholesale status
							this.getWholesaleStatus();
						} else {
							this.dealerName = '-';
							this.isNoRecordFound = true;
						}
						this.toggleCommitButton(this.wholesaleStatus);
						this.spinner.hide();
						this.showDealerDetailDiv = true;
						this.isDealerGroupDetailDiv = true;
					});
				});
		});
	}

	public getWholesaleStatus() {
		this.wholesaleService.getWholesaleStatus().subscribe(response => {
			this.wholesaleStatus = response.wholesaleStatus;
			this.wholesaleStatusMonth = response.month;
			if(this.wholesaleStatusMonth === this.wholesaleCurrentMonthAndYear) {
				this.isStatus = true;
			}
			this.toggleCommitButton(this.wholesaleStatus);
		});
	}

	public compareInitialAndFinalWholesale(updatedWholesaleCommitmentList: WholesaleResponse[]) {
		for (let i = 0; i < this.finalWholesaleViewList.length; i++) {
			if (this.finalWholesaleViewList[i].allocationVehicleVolumeCommitCount !==
				this.initialWholesaleViewList[i].allocationVehicleVolumeCommitCount) {
				updatedWholesaleCommitmentList.push(this.finalWholesaleViewList[i]);
			}
		}
	}

	public mappingDealerZoneManagerMailId(wholesaleList: WholesaleResponse[]) {
		this.toZoneManagerEmailId = this.zoneMangerEmailList.filter(zoneId => zoneId.zone === wholesaleList[0].dealerZoneCode)[0].emailId;
	}

	private registerErrorHandler() {
		this.httpInterceptorService.errorStream$().subscribe(errorObj => {
			const url: string = errorObj.url;
			if (!url.includes('WholesaleCommitmentService')) {
				const response = this.isJson(errorObj.error) ? JSON.parse(errorObj.error) : errorObj.error;
				this.isServerError = true;
				let errMessage;
				if (response instanceof ArrayBuffer) {
					errMessage = response;
				} else if (this.isJson(response.error.messages[0])) {
					errMessage = JSON.parse(response.error.messages[0]);
				} else {
					errMessage = response.error.messages[0];
				}

				if (errMessage.error) {
					this.errorMsg = errMessage.error;
				} else {
					this.errorMsg = errorObj.statusText;
				}
			} else {
				this.isServerError = true;
				if (errorObj.error.error) {
					this.errorMsg = errorObj.error.error;
				} else {
					this.errorMsg = errorObj.statusText;
				}
			}
			this.spinner.hide();
		});
	}

	public sortWholesaleCommitmentList(commitmentList: WholesaleResponse[], sortBy: string) {
		commitmentList.sort((n1, n2) => {
			if (n1[sortBy] > n2[sortBy]) {
				return 1;
			}
			if (n1[sortBy] < n2[sortBy]) {
				return -1;
			}
			return 0;
		});
	}

	private isJson(req): boolean {
		try {
			JSON.parse(req);
			return true;
		} catch (err) {
			return false;
		}
	}
}
