import {Injectable} from '@angular/core';
import {Authorisation} from '../models/authorisation.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class WholesaleAPIAuthService {

	constructor(private http: HttpClient) {
	}

	/*public getAuthorisationData() {
		return this.http.get<Authorisation>(environment.adfsUrl, {
			headers: new HttpHeaders().set('Authorization', 'Basic ' +
				btoa(environment.userName + ':' + environment.password))
		});
	}*/
}
