import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {WholesaleConstants} from '../constants/wholesale.constants';

@Injectable()
export class BulkOrderService {
	private getVehicleLineUrl = sessionStorage.getItem('baseAllocationUrl') + WholesaleConstants.getVehicleLineUrl;
	private specificationMappedUrl = sessionStorage.getItem('baseBulkOrderCreationUrl') + WholesaleConstants.getSpecificationByMarketUrl;
	private saveSaveDealerMixByMarketAndVehicleLineUrl = sessionStorage.getItem('baseBulkOrderCreationUrl') + WholesaleConstants.saveSaveDealerMixUrl;

	constructor(private httpClient: HttpClient) {
	}

	getVehicleLineBoc(isSeer: boolean) {
		const params = new HttpParams().set('specificationMarket', sessionStorage.getItem('loggedInUserMarketCode'))
			.set('isSeer', String(isSeer));
		return this.httpClient.get<any>(this.getVehicleLineUrl, {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + sessionStorage.getItem('authToken')
			}), params
		});
	}

	getSpecificationsAndMixMapped(marketKey: string, vehicleLineCode: number, wholesaleMonth: any, productionMonth: any, dealerId: any) {
		const params = new HttpParams()
			.set('productionMonth', productionMonth)
			.set('wholesaleMonth', wholesaleMonth)
			.set('dealerId', dealerId);
		return this.httpClient.get<any>(this.specificationMappedUrl + marketKey + '/vehicleline/' + vehicleLineCode + '/specifications/dealermix', {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + sessionStorage.getItem('authToken')
			}), params
		});
	}

	saveDealerMixByMarketAndVehicleLine(dealerGroupMixList: any) {
		return this.httpClient.post(
			this.saveSaveDealerMixByMarketAndVehicleLineUrl  + 'vehicleline/savedealermix',
			dealerGroupMixList, {
				responseType: 'text',
				headers: new HttpHeaders({
					Authorization: 'Bearer ' + sessionStorage.getItem('authToken')
				})
			});
	}
}
