import {Component, Input, Output} from '@angular/core';
import {WholesaleResponse} from '../models/wholesale-response.model';

@Component({
	selector: 'wholesale-dealer-view',
	templateUrl: './wholesale-dealer-view.component.html',
	styleUrls: ['./wholesale-dealer-view.component.css']
})
export class WholesaleDealerViewComponent {

	@Input() @Output() wholesaleCommitmentResponseList;
	public selectAllForCopy: boolean;
	public isDealerGroupDetailDiv = false;
	public vehicleType: any;
	public canViewDealerGroup = false;

	handleSelectAll() {
		for (const element of this.wholesaleCommitmentResponseList) {
			element.selected = this.selectAllForCopy;
		}
	}

	checkIfAllSelected() {
		this.selectAllForCopy = this.wholesaleCommitmentResponseList.every(function (item: any) {
			return item.selected === true;
		});
	}

	public isVehicleLineTypeFirstIndexMatch(dealerRowData: WholesaleResponse, inputRowIndex: number): boolean {
		const foundRowIndex = this.wholesaleCommitmentResponseList.findIndex(dealer => dealer.vehicleLineType === dealerRowData.vehicleLineType);
		const isIndexMatching = foundRowIndex === inputRowIndex;
		if (isIndexMatching) {
			if (dealerRowData.vehicleLineType === 'CR') {
				this.vehicleType = 'CAR';
			} else {
				this.vehicleType = 'CV';
			}
		}
		return isIndexMatching;
	}

	public isRowVehicleLineFirstIndexMatch(dealerRowData: WholesaleResponse, inputRowIndex: number): boolean {
		const foundRowIndex = this.wholesaleCommitmentResponseList.findIndex(dealer => (dealer.regionalAllocationVLCode === dealerRowData.regionalAllocationVLCode
			&& dealer.vehicleLineAndRegionalVehicleLineCode === dealerRowData.vehicleLineAndRegionalVehicleLineCode && dealer.productionMonth === dealerRowData.productionMonth));
		return foundRowIndex === inputRowIndex;
	}

	copyOver() {
		for (const element of this.wholesaleCommitmentResponseList) {
			if (element.selected) {
				element.allocationVehicleVolumeCommitCount = element.productionOffer;
			}
		}
	}

	refreshDataGrid(volumeCommitment: number, rowdata: any) {
		rowdata.allocationVehicleVolumeCommitCount = Number(volumeCommitment);
	}
}
