import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {WholesaleRequest} from '../models/wholesale-request.model';
import {WholesaleResponse} from '../models/wholesale-response.model';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {WholesaleConstants} from '../constants/wholesale.constants';
import {WholesaleStatusResponse} from '../models/wholesalestatus-response.model';
import {ProductionOfferResponse} from '../models/production-offer-response.model';
import {DealerResponse} from '../models/dealer-details';
import {environment} from '../../environments/environment';
import {WholesaleEmailRequest} from '../models/wholesale-email-request';

@Injectable()
export class WholesaleService {

	constructor(private httpClient: HttpClient) {
	}

	private wholesaleRequest: WholesaleRequest;
	private dealerRequest: DealerResponse[];
	private getWholesaleStatusUrl = sessionStorage.getItem('baseCommitmentUrl') + environment.getWholesaleStatusUrl;
	private getAllVolumeCommitUrl = sessionStorage.getItem('baseCommitmentUrl') + environment.getAllVolumeCommitUrl;
	private getVolumeCommitmentDataforAudit = sessionStorage.getItem('baseCommitmentUrl') + environment.getVolumeCommitDataForAudit;
	private getProductionOfferUrl = sessionStorage.getItem('baseAllocationUrl') + environment.getProductionOfferUrl;
	private getDealerDetailsUrl = sessionStorage.getItem('baseDealerServiceUrl') + environment.getDealerDetailUrl;
	private saveVolumeCommitUrl = sessionStorage.getItem('baseCommitmentUrl') + environment.saveVolumeCommitUrl;
	private getDealerGroupsUrl = sessionStorage.getItem('baseDealerServiceUrl') + environment.getDealerGroupsUrl;
	private getDealerIdListUrl = sessionStorage.getItem('baseDealerServiceUrl') + environment.getDealerIdlistUrl;
	private getDistrictAndZoneListUrl = sessionStorage.getItem('baseDealerServiceUrl') + environment.getDistrictAndZoneListUrl;
	private postEmailMessageUrl = sessionStorage.getItem('baseAllocationUrl') + environment.postEmailServiceUrl;
	private getMessageUrl = sessionStorage.getItem('baseAllocationUrl') + environment.getMessageUrl;
	private cdsId = sessionStorage.getItem('cdsId');

	static handleError(error: any) {
		return throwError(() => error);
	}

	private static getHttpParams(districtCode: string, zoneCode: string, wholesaleMonth: string, dealerGroupName: string, dealerId: string) {
		return new HttpParams().set('marketCode', sessionStorage.getItem('loggedInUserMarketCode'))
			.set('districtCode', districtCode)
			.set('zoneCode', zoneCode)
			.set('wholesaleMonth', wholesaleMonth)
			.set('dealerGroupId', dealerGroupName)
			.set('dealerId', dealerId);
	}

	public getWholesaleStatus(): Observable<any> {
		const params = new HttpParams().set('specificationMarket', sessionStorage.getItem('loggedInUserMarketCode'));
		return this.httpClient.get<WholesaleStatusResponse>(this.getWholesaleStatusUrl, {
			headers: this.getHttpHeadersApigee(), params
		});
	}

	public getProductionOffers(districtCode: string, zoneCode: string, dealerGroupName: string, dealerId: string, wholesaleMonth: string) {

		const params = WholesaleService.getHttpParams(districtCode, zoneCode, wholesaleMonth, dealerGroupName, dealerId);
		return this.httpClient.get<ProductionOfferResponse[]>(this.getProductionOfferUrl, {
			headers: this.getHttpHeadersApigee(), params
		}).pipe(catchError(WholesaleService.handleError));
	}

	public getProductionOffersFromDealerRequest(dealerResponse: DealerResponse[], wholesaleMonth: string) {
		const params = new HttpParams().set('specificationMarket', sessionStorage.getItem('loggedInUserMarketCode'))
			.set('wholesaleMonth', wholesaleMonth);
		this.dealerRequest = dealerResponse;
		return this.httpClient.post<ProductionOfferResponse[]>(this.getProductionOfferUrl, this.dealerRequest, {
			headers: this.getHttpHeadersApigee(), params
		}).pipe(catchError(WholesaleService.handleError));
	}

	public getDealerDetails(districtCode: string, zoneCode: string, dealerGroupName: string, dealerId: string) {
		const dealerdetailsrequest = new DealerDetailRequest();
		dealerdetailsrequest.districtCode = districtCode;
		dealerdetailsrequest.zoneCode = zoneCode;
		dealerdetailsrequest.dealerGroupId = dealerGroupName;
		dealerdetailsrequest.dealerId = dealerId;
		dealerdetailsrequest.marketCode = sessionStorage.getItem('loggedInUserMarketCode');
		return this.httpClient.post<DealerResponse[]>(this.getDealerDetailsUrl, dealerdetailsrequest, {
			headers: this.getHttpHeadersApigee(),
		}).pipe(catchError(WholesaleService.handleError));
	}

	public getWholesaleDetail(dealerProductionOffers: ProductionOfferResponse[], wholesaleMonth: string) {

		const params = new HttpParams().set('marketCode', sessionStorage.getItem('loggedInUserMarketCode'))
			.set('wholesaleMonth', wholesaleMonth);
		return this.httpClient.post<WholesaleResponse[]>(this.getAllVolumeCommitUrl, dealerProductionOffers, {
			headers: this.getHttpHeadersApigee(), params
		}).pipe(catchError(WholesaleService.handleError));
	}

	getVolumeCommitmentByMarketAndWholesaleMonth(wholesaleCurrentMonthAndYear: string) {
		const params = new HttpParams().set('specificationMarketCode', sessionStorage.getItem('loggedInUserMarketCode'))
			.set('wholesaleMonth', wholesaleCurrentMonthAndYear);
		return this.httpClient.get<any[]>(this.getVolumeCommitmentDataforAudit, {
			headers: this.getHttpHeadersApigee(), params
		}).pipe(catchError(WholesaleService.handleError));
	}

	public getDealerGroupList(): Observable<WholesaleResponse[]> {
		const params = new HttpParams().set('marketCode', sessionStorage.getItem('loggedInUserMarketCode'));
		return this.httpClient.get<WholesaleResponse[]>(this.getDealerGroupsUrl, {
			headers: this.getHttpHeadersApigee(), params
		}).pipe(catchError(WholesaleService.handleError));
	}
	public getDealerIdList() {
		const params = new HttpParams().set('marketCode', sessionStorage.getItem('loggedInUserMarketCode'));
		return this.httpClient.get<WholesaleResponse[]>(this.getDealerIdListUrl, {
			headers: this.getHttpHeadersApigee(), params
		}).pipe(catchError(WholesaleService.handleError));
	}

	public getDistrictAndZoneList() {
		const params = new HttpParams().set('marketCode', sessionStorage.getItem('loggedInUserMarketCode'));
		return this.httpClient.get<any[]>(this.getDistrictAndZoneListUrl, {
			headers: this.getHttpHeadersApigee(), params
		}).pipe(catchError(WholesaleService.handleError));
	}

	public getMessage(wholesaleMonth: string) {
		const params = new HttpParams().set('specificationMarket', sessionStorage.getItem('loggedInUserMarketCode'))
			.set('wholesaleMonth', wholesaleMonth);
		return this.httpClient.get<any[]>(this.getMessageUrl, {
			headers: this.getHttpHeadersApigee(), params
		}).pipe(catchError(WholesaleService.handleError));
	}

	public saveWholesaleCommitment(authToken: string, commitmentVolume: number, dealerId: string, productionMonth: string, modelYear: string, rgVLCode: string, ptvl: string, wholesaleMonth: number, isApproved: boolean) {

		this.wholesaleRequest = new WholesaleRequest(dealerId, WholesaleConstants.dealerType, +modelYear, rgVLCode, ptvl, +productionMonth, commitmentVolume, sessionStorage.getItem('loggedInUserMarketCode'), +wholesaleMonth, isApproved);
		return this.httpClient.post<Observable<WholesaleResponse[]>>(this.saveVolumeCommitUrl, this.wholesaleRequest, {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + sessionStorage.getItem('authToken'),
				'requester-app': this.cdsId
			})
		});
	}

	public sendEmailMesaageToZoneManager(toZoneManagerEmailId: string, wholesaleRequest: WholesaleEmailRequest[]) {
		const params = new HttpParams().set('marketCode', sessionStorage.getItem('loggedInUserMarketCode'))
			.set('mailTo', toZoneManagerEmailId);
		return this.httpClient.post(this.postEmailMessageUrl, wholesaleRequest, {
			headers: this.getHttpHeadersApigee(), params
		}).pipe(catchError(WholesaleService.handleError));
	}

	private getHttpHeadersApigee() {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + sessionStorage.getItem('authToken'),
			'requester-app': this.cdsId
		});
	}
}

class DealerDetailRequest {
	marketCode: string;
	districtCode: string;
	zoneCode: string;
	dealerGroupId: string;
	dealerId: string;
}
