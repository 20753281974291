import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	template: `
		Redirecting to Login Server
	`
})
export class LoginComponent implements OnInit {
	ngOnInit() {
		const url = window.location.href;

		let redirectUrl;
		if (url.indexOf('dealerconnection.com') > 0) {
			redirectUrl = environment.redirectUrl_e;
		} else {
			redirectUrl = environment.redirectUrl_i;
		}
		let _endPoint = '';
		// if (environment.local === true) {
		// 	_endPoint =
		// 		environment.oauthUrl +
		// 		'client_id=' +
		// 		environment.client_id +
		// 		'&response_type=token+id_token&redirect_uri=' +
		// 		encodeURIComponent(redirectUrl);
		// } else
		// {
		_endPoint = redirectUrl;
		// }
		window.location.href = _endPoint;
	}
}
