import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

	public errorStream: Subject<any> = new Subject();
	public isServerError: boolean;

	public errorStream$(): Observable<any> {
		return this.errorStream.asObservable();
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					let errorMessage = '';
					if (error.error instanceof ErrorEvent) {
						errorMessage = `Error: ${error.message}`;
					} else {
						if (request.method === 'GET') {
							this.errorStream.next(error);
						}
						errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
					}
					this.errorStream.next(error);
					return throwError(errorMessage);

				})
			);
	}
}
