import {Component, OnInit} from '@angular/core';
import {UserService} from '../service/user.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup} from '@angular/forms';
import * as  multipleMarketUsers from '../../assets/data/multipleMarketUsers.json';
import * as  marketListJson from 'src/assets/data/marketList.json';
import {WholesaleService} from '../service/wholesale.service';
import {WholesaleConstants} from '../constants/wholesale.constants';
import {formatDate} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.css']
})

export class HomePageComponent implements OnInit {

	public show = false;
	public unauthShow = false;
	public marketList: any[];
	public marketCodeShow = false;
	public dashBoardShow = false;
	public messageShow = false;
	public messageBody: any;
	public formHome: FormGroup;
	public unauthorizationForm: FormGroup;
	public errorMsg: string;
	public wholesaleCurrentMonth: any;
	public wholesaleLetter: any[];
	public isAuthorizedUser: boolean;
	public roleCode;
	public loggedInCdsId;
	public multipleMarketUsersList;

	constructor(private userService: UserService, private router: Router,
				private translate: TranslateService, private wholesaleService: WholesaleService,
				private sanitizer: DomSanitizer) {

	}

	ngOnInit() {
		this.authoriseUser();
		this.marketList = (marketListJson as any).default;
		this.loggedInCdsId = sessionStorage.getItem('cdsId').toLowerCase();

		this.wholesaleCurrentMonth = this.transformDate(new Date(), WholesaleConstants.dateFormatYYYYMM);
		this.multipleMarketUsersList = (multipleMarketUsers as any).default;
		this.wholesaleService.getMessage(this.wholesaleCurrentMonth).subscribe((response: any[]) => {
			this.wholesaleLetter = response;
			if (this.wholesaleLetter.length !== 0) {
				this.messageShow = true;
				this.messageBody = this.sanitizer.bypassSecurityTrustHtml(this.wholesaleLetter[0].messageBody);
			}
		});

		this.roleCode = sessionStorage.getItem('roleCode');
		if (this.roleCode === '4' || this.roleCode === '5') {
			this.marketCodeShow = true;
		}
		if (this.roleCode === '1' || this.roleCode === '4' || this.roleCode === '5') {
			this.dashBoardShow = true;
		}
		if (this.multipleMarketUsersList[this.loggedInCdsId]) {
			this.marketCodeShow = true;
			this.marketList = this.multipleMarketUsersList[this.loggedInCdsId];
		}

	}

	public onMarketCodeSelect(marketCode: any) {
		sessionStorage.setItem('loggedInUserMarketCode', marketCode);
		sessionStorage.setItem('loggedInUserMarketText', this.marketList.filter(
			data => data.value === marketCode
		)[0].label);
		this.router.navigate(['/wholesale-by-zone']);
	}

	authoriseUser() {
		this.isAuthorizedUser = this.userService.isAuthorisedUser();
		if (!this.isAuthorizedUser) {
			this.unauthShow = true;
		} else {
			this.show = true;
		}
	}

	public transformDate(date: string | number | Date, pattern: string) {
		return formatDate(date, pattern, 'en-US');
	}

}
