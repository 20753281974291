import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
	public exportAsExcelFile(dealersVolumeCommitment: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dealersVolumeCommitment);
		const wscols = this.AutoFitHeader(dealersVolumeCommitment);
		worksheet['!cols'] = wscols;
		const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
		const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
		this.saveAsExcelFile(excelBuffer, excelFileName);
	}

	public saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}

	private AutoFitHeader(dealersVolumeCommitment: any[]) {
		const jsonKeys = Object.keys(dealersVolumeCommitment[0]);
		let objectMaxLength = [];
		for (const element of dealersVolumeCommitment) {
			const value = element;
			for (let j = 0; j < jsonKeys.length; j++) {
				if (typeof value[jsonKeys[j]] == 'number') {
					objectMaxLength[j] = 5;
				} else {
					const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;
					objectMaxLength[j] =
						objectMaxLength[j] >= l
							? objectMaxLength[j]
							: l;
				}
			}

			const key = jsonKeys;
			for (let j = 0; j < key.length; j++) {
				objectMaxLength[j] =
					objectMaxLength[j] >= key[j].length
						? objectMaxLength[j]
						: key[j].length;
			}
		}

		return objectMaxLength.map(w => {
			return {width: w};
		});
	}
}
