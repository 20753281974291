import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class LoginService {
	tokenExp: number;
	// public userName = environment.userName;
	// public password = environment.password;
	// public adfsUrl = environment.adfsUrl;
	public isLoggedIn: Subject<boolean> = new Subject();

	constructor(private httpClient: HttpClient) {
	}

	parseSessionStorage() {
		if (sessionStorage.length > 0) {
			this.tokenExp = sessionStorage['tokenExp'];
		}
	}

	public isLoggedIn$(): Observable<boolean> {
		return this.isLoggedIn.asObservable();
	}

	isTokenExpired(): boolean {
		const epoch = Math.trunc(new Date().getTime() / 1000);
		if (!this.tokenExp) {
			return true;
		}
		return (epoch >= this.tokenExp);
	}

	checkLogin(): boolean {
		this.parseSessionStorage();
		if (this.isTokenExpired()) {

			sessionStorage['tokenExp'] = null;
			sessionStorage['encodedIdToken'] = null;
			sessionStorage['encodedAccessToken'] = null;
			return false;
		}
		// this.getAuthorisationToken();
		return true;
	}

	/*public getAuthorisationToken() {
		this.getAuthorisationData()
			.subscribe(
				(response: Authorisation) => {
					sessionStorage.setItem('authToken', response.access_token);
					this.isLoggedIn.next(true);
				}
			);
	}*/

	/*getAuthorisationData() {
		return this.httpClient.get<Authorisation>(this.adfsUrl, {
			headers: new HttpHeaders().set('Authorization', 'Basic ' +
				btoa(this.userName + ':' + this.password))
		});

	}*/

	getApsRoles(url: string) {
		const params = new HttpParams().set('cdsId', sessionStorage.getItem('cdsId'));
		return this.httpClient.get(url, {
			headers: this.getAuthTokenForApigee(), params
		}).toPromise();
	}

	protected getAuthTokenForApigee() {
		return new HttpHeaders({
			Authorization: 'Bearer ' + sessionStorage.getItem('authToken'),
			'Access-Control-Allow-Origin': '*'
		});
	}
}
