import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {OAuthCallbackComponent} from './oauth-callback.component';
import {HomePageComponent} from './home-page/home-page.component';
import {WholesaleCommitmentByZoneComponent} from './wholesale-commitment-by-zone/wholesale-commitment-by-zone.component';
import {WholesaleDealerCommitmentComponent} from './wholesale-dealer-commitment/wholesale-dealer-commitment.component';
import {WholesaleDealerCommitmentHistory} from './wholesale-dealer-commitment-history/wholesale-dealer-commitment-history.component';
import {OAuthCallbackLocalComponent} from './oauth-callback-local.component';
import {LogoutPageComponent} from './logout-page/logout-page.component';
import {DealerMixVolumeComponent} from './dealer-mix-volume/dealer-mix-volume.component';
import {CommitmentAuditTrailComponent} from './commitment-audit-trail/commitment-audit-trail.component';
import {AuthenticationGuard, OauthCallbackComponent, OauthTokenGuard} from '@wame/adfs-angular';


const routes: Routes = [
	{
		path: 'oauth',
		component: OauthCallbackComponent,
		canActivate: [OauthTokenGuard]
	},
	{
		path: '',
		redirectTo: '/home-page',
		pathMatch: 'full'
	},
	//{ path: '', redirectTo: '/oauth-callback', pathMatch: 'full' },
	{
		path: 'home-page',
		component: HomePageComponent,
		canActivate: [AuthenticationGuard]
	},
	{
		path: 'logout',
		component: LogoutPageComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'oauth-callback',
		component: OAuthCallbackLocalComponent
	},
	{
		path: 'access_token',
		component: OAuthCallbackComponent
	},
	{
		path: 'wholesale-by-zone',
		component: WholesaleCommitmentByZoneComponent, canActivate: [AuthenticationGuard]
	},
	{
		path: 'CommitmentAudit',
		component: CommitmentAuditTrailComponent, canActivate: [AuthenticationGuard]
	},
	{
		path: 'wholesaleDealerCommitment',
		component: WholesaleDealerCommitmentComponent,
		canActivate: [AuthenticationGuard]
	},
	{
		path: 'WholesaleDealerCommitmentHistory',
		component: WholesaleDealerCommitmentHistory,
		canActivate: [AuthenticationGuard]
	},
	{
		path: 'dealer-mix-volume',
		component: DealerMixVolumeComponent, canActivate: [AuthenticationGuard]
	},

	{
		path: '**',
		component: HomePageComponent, canActivate: [AuthenticationGuard]
	}

];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}
