import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';

@Injectable({
	providedIn: 'root'
})
export class WholesaleMessageService {

	constructor(private message: MessageService) {
	}
	public showMesssge(detail: string, severity: string) {
		this.clearMessage();
		this.message.add({severity, detail, life: 10000});
	}

	private clearMessage() {
		if (document.getElementsByClassName('ui-growl-item-container').length > 0) {
			document.getElementsByClassName('ui-growl-item')[0].remove();
		}
	}
}
