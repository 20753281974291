import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {WholesaleAPIAuthService} from './service/wholesale-commitment-api-auth.service';
import {Authorisation} from './models/authorisation.model';
import * as  datamarketLanguageMapping from 'src/assets/data/market-language-mapping.json';
import * as  switzerlandMapping from 'src/assets/data/switzerland-i18n.json';
import {HttpErrorInterceptor} from './service/http-error.interceptor';

/*
This component is called by the UAA when following the redirect URL post authentication
It pulls the tokens from the web fragment ( ?#access_token=...&id_token=... ) and stores them in local storage
 */
@Component({
	template: `
		<h3>Redirecting to requested URL</h3>
	`
})
export class OAuthCallbackLocalComponent implements OnInit {
	public isServerError = false;
	public errorMsg: string;
	private switzerlandSelectLanguage: any[] = (switzerlandMapping as any).default;
	private marketLanguageMapping: any[] = (datamarketLanguageMapping as any).default;
	private marketInfo: MarketInfoModel;
	private authToken: string;

	constructor(private _router: Router, private httpInterceptorService: HttpErrorInterceptor,
				private wholesaleAPIAuthService: WholesaleAPIAuthService) {
	}

	ngOnInit() {
		// this.getAuthToken();
		if (document.URL.indexOf('&') === -1) {
			location.reload();
		}
		let  callbackResponse = new Map();
		if ( document.URL.indexOf('#') !== -1 ) {
			callbackResponse.set(0,document.URL.split('#')[1]);
			const responseParameters = callbackResponse.get(0).split('&');
			const parameterMap = new Map<string, string>();
			for (let i = 0; i < responseParameters.length; i++) {
				const[key, value] = responseParameters[i].split('=');
				if(parameterMap.has(key)) {
					parameterMap.set(key,value);
				}else{
					parameterMap.set(key,value);
				}
			}
			if (
				parameterMap.get('access_token') != null &&
				parameterMap.get('id_token') != null
			) {
				this.createSessionVariables(parameterMap);
				this._router.navigate(['/home-page']);
			}
		} else {
			console.log('Auth token not found');
		}
	}

	private createSessionVariables(parameterMap: Map<string, string>) {
		const expiration = Number(parameterMap.get('expires_in')) + (Math.trunc(new Date().getTime() / 1000));
		const accessToken = JSON.parse(atob(parameterMap.get('access_token').split('.')[1]));
		const idToken = JSON.parse(atob(parameterMap.get('id_token').split('.')[1]));
		const url = window.location.href;
		let loggedInUserMarketCode = '';
		let loggedInUserMarketText = '';
		let loggedInUserLanguageCode = '';

		this.marketInfo = this.marketLanguageMapping.filter(market => market.dealerMarket === idToken.user_attributes.c[0])[0].marketInfo;
		loggedInUserMarketCode = this.marketInfo.marketCode;
		loggedInUserMarketText = this.marketInfo.marketText;

		sessionStorage.setItem('encodedIdToken', parameterMap.get('id_token'));
		sessionStorage.setItem('encodedAccessToken', parameterMap.get('access_token'));
		sessionStorage.setItem('tokenExp', String(expiration));
		sessionStorage.setItem('cdsId', accessToken.user_name.toUpperCase());
		sessionStorage.setItem('loggedInUserMarketCode', loggedInUserMarketCode);
		sessionStorage.setItem('loggedInUserMarketText', loggedInUserMarketText);
		sessionStorage.setItem('loggedInSiteCode', idToken.user_attributes.fordSiteCode[0].substring(3, 8));

		if (url.indexOf('dealerconnection.com') > 0) {
			sessionStorage.setItem('baseCommitmentUrl', environment.baseCommitmentUrl_e);
			sessionStorage.setItem('baseProductionOfferUrl', environment.baseProductionOfferUrl_e);
			sessionStorage.setItem('baseAllocationUrl', environment.baseWholesaleAllocationUrl_e);
			sessionStorage.setItem('baseDealerServiceUrl', environment.baseDealerService_e);
			sessionStorage.setItem('baseBulkOrderCreationUrl', environment.baseBulkOrderCreationUrl_e);
		} else {
			sessionStorage.setItem('baseCommitmentUrl', environment.baseCommitmentUrl_i);
			sessionStorage.setItem('baseDealerServiceUrl', environment.baseDealerServiceUrl_i);
			sessionStorage.setItem('baseProductionOfferUrl', environment.baseProductionOfferUrl_i);
			sessionStorage.setItem('baseAllocationUrl', environment.baseWholesaleAllocationUrl_i);
			sessionStorage.setItem('baseBulkOrderCreationUrl', environment.baseBulkOrderCreationUrl_i);
		}

		const gotdGWSRoles = idToken.user_attributes.memberof.filter(role => role.includes('GOTDGA-Roles'));

		for (let i = 0; i < gotdGWSRoles.length; i++) {
			if (gotdGWSRoles[i].toLowerCase() === 'GOTDGA-Roles-Zone Manager'.toLowerCase() || gotdGWSRoles[i].toLowerCase() === 'GOTDGA-Roles-NSC'.toLowerCase()) {
				sessionStorage.setItem('roleCode', '1');
				break;
			} else if (gotdGWSRoles[i].toLowerCase() === 'GOTDGA-Roles-CMA Dealer'.toLowerCase()) {
				sessionStorage.setItem('roleCode', '2');
				break;
			} else if (gotdGWSRoles[i].toLowerCase() === 'GOTDGA-Roles-Dealer Group'.toLowerCase()) {
				sessionStorage.setItem('roleCode', '3');
				break;
			} else if (gotdGWSRoles[i].toLowerCase() === 'GOTDGA-Roles-Product Team'.toLowerCase()) {
				sessionStorage.setItem('roleCode', '4');
				break;
			} else if (gotdGWSRoles[i].toLowerCase() === 'GOTDGA-Roles-DemoUser'.toLowerCase()) {
				sessionStorage.setItem('roleCode', '5');
				break;
			}
		}

		if (sessionStorage.getItem('roleCode') !== '2' && sessionStorage.getItem('roleCode') !== '3') {
			loggedInUserLanguageCode = 'en_GB';
		} else {
			loggedInUserLanguageCode = this.marketInfo.locale === 'CH' ?
				this.validateSwitzerlandDealerId(idToken).length > 0 ?
					this.validateSwitzerlandDealerId(idToken)[0].language :
					this.registerErrorHandler() : this.marketInfo.locale;
		}
		sessionStorage.setItem('loggedInUserLanguageCode', loggedInUserLanguageCode);
	}

	private validateSwitzerlandDealerId(idToken: any): any[] {
		return this.switzerlandSelectLanguage.filter(dealer => dealer.dealerId ===
			idToken.user_attributes.fordSiteCode[0].match(/\d+/g).toString());
	}

	private registerErrorHandler() {
		this.httpInterceptorService.errorStream$().subscribe(errorObj => {
			this.isServerError = true;
			if (errorObj.error.error) {
				this.errorMsg = errorObj.error.error;
			} else {
				this.errorMsg = errorObj.statusText;
			}

		});
	}

	/*private getAuthToken() {
		this.wholesaleAPIAuthService.getAuthorisationData().subscribe(
			(response: Authorisation) => {
				this.authToken = response.access_token;
				sessionStorage.setItem('authToken', this.authToken);
			}
		);
	}*/

}

interface MarketInfoModel {
	marketCode: string;
	marketText: string;
	locale: string;
}
