import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpErrorInterceptor} from './service/http-error.interceptor';
import {HomePageComponent} from './home-page/home-page.component';
import {UserService} from './service/user.service';
import {WholesaleAPIAuthService} from './service/wholesale-commitment-api-auth.service';
import {WholesaleMessageService} from './service/wholesale-message.service';
import {WholesaleService} from './service/wholesale.service';
import {BulkOrderService} from './service/bulk-order-service';
import {LoginService} from './service/login.service';
import {LoginRoutingModule} from './login/login-routing.module';
import {LogoutPageComponent} from './logout-page/logout-page.component';
import {LoginModule} from './login/login.module';
import {OAuthCallbackComponent} from './oauth-callback.component';
import {OAuthCallbackLocalComponent} from './oauth-callback-local.component';
import {WholesaleCommitmentByZoneComponent} from './wholesale-commitment-by-zone/wholesale-commitment-by-zone.component';
import {WholesaleDealerCommitmentComponent} from './wholesale-dealer-commitment/wholesale-dealer-commitment.component';
import {WholesaleTreeComponent} from './wholesale-tree/wholesale-tree.component';
import {WholesaleDealerCommitmentHistory} from './wholesale-dealer-commitment-history/wholesale-dealer-commitment-history.component';
import {WholesaleDealerViewComponent} from './wholesale-dealer-view/wholesale-dealer-view.component';
import {DealerMixVolumeComponent} from './dealer-mix-volume/dealer-mix-volume.component';
import {CommitmentAuditTrailComponent} from './commitment-audit-trail/commitment-audit-trail.component';
import {ExcelService} from './service/excel.service';
import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {CheckboxModule} from 'primeng/checkbox';
import {ToastModule} from 'primeng/toast';
import {SidebarModule} from 'primeng/sidebar';
import {InputTextModule} from 'primeng/inputtext';
import {TooltipModule} from 'primeng/tooltip';
import {TableModule} from 'primeng/table';
import {FieldsetModule} from 'primeng/fieldset';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {StyleClassModule} from 'primeng/styleclass';
import {AdfsAngularModule, UserIdService} from '@wame/adfs-angular';
import {environment} from '../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		HomePageComponent,
		AppComponent,
		NavComponent,
		OAuthCallbackLocalComponent,
		OAuthCallbackComponent,
		WholesaleDealerCommitmentComponent,
		WholesaleCommitmentByZoneComponent,
		WholesaleTreeComponent,
		WholesaleDealerCommitmentHistory,
		LogoutPageComponent,
		WholesaleDealerViewComponent,
		DealerMixVolumeComponent,
		CommitmentAuditTrailComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,
		SidebarModule,
		LoginModule,
		LoginRoutingModule,
		HttpClientModule,
		FormsModule,
		CheckboxModule,
		ToastModule,
		NgxSpinnerModule,
		AppRoutingModule,
		ButtonModule,
		InputTextModule,
		TooltipModule,
		TableModule,
		FieldsetModule,
		DropdownModule,
		InputSwitchModule,
		ConfirmDialogModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		StyleClassModule,
		AdfsAngularModule.forRoot({
			openidUrl: environment.openidUrl,
			openidClientId: environment.openidClientId,
			openidResource: environment.openidResource
		})
	],
	providers: [MessageService, NgxSpinnerService, TranslateService, ConfirmationService, WholesaleService, UserService,
		LoginService, WholesaleAPIAuthService, WholesaleMessageService, BulkOrderService, HttpErrorInterceptor, ExcelService,
		UserIdService,
		{
			provide: HTTP_INTERCEPTORS,
			useExisting: HttpErrorInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})

export class AppModule {
}
