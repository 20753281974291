import {formatDate} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {WholesaleConstants} from '../constants/wholesale.constants';
import {Authorisation} from '../models/authorisation.model';
import {ExcelService} from '../service/excel.service';
import {UserService} from '../service/user.service';
import {WholesaleAPIAuthService} from '../service/wholesale-commitment-api-auth.service';
import {WholesaleService} from '../service/wholesale.service';

@Component({
	selector: 'commitment-audit-trail',
	templateUrl: './commitment-audit-trail.component.html',
	styleUrls: ['./commitment-audit-trail.component.scss']
})
export class CommitmentAuditTrailComponent implements OnInit {
	public isAuthorizedUser = false;
	public wholesaleCurrentMonth: any;
	public isNoRecordFound = false;
	public volumeCommitmentResponds: any[] = [];
	public data: any[] = [];
	public systemMonth: any;
	public authToken: string;
	public wholesaleCurrentMonthAndYear: any;

	constructor(private wholesaleAPIAuthService: WholesaleAPIAuthService,
				private wholesaleService: WholesaleService, private excelService: ExcelService,
				private userService: UserService) {
	}

	ngOnInit() {
		// this.getAuthToken();
		this.isAuthorizedUser = this.userService.isAuthorisedUser();
		this.wholesaleCurrentMonth = this.transformDate(new Date(), WholesaleConstants.dateFormatMMMYYYY);
		this.wholesaleCurrentMonthAndYear = this.transformDate(new Date().setMonth(new Date().getMonth()), WholesaleConstants.dateFormatYYYYMM);
		this.systemMonth = new Date().toLocaleString('en-us', {month: 'long'});
		this.getDealerVolumeCommitmentData();
	}

	exportDealerVolumeCommitmentInExcel() {
		if (this.volumeCommitmentResponds.length === 0) {
			this.isNoRecordFound = true;
		} else {
			this.excelService.exportAsExcelFile(this.volumeCommitmentResponds, 'TraceDealerCommitment');
		}

	}

	public transformDate(date: string | number | Date, pattern: string) {
		return formatDate(date, pattern, 'en-US');
	}

	public getDealerVolumeCommitmentData() {
		this.wholesaleService.getVolumeCommitmentByMarketAndWholesaleMonth(this.wholesaleCurrentMonthAndYear)
			.subscribe((response: any[]) => {
				this.data = response['vehicleLineCommitmentList'];
				this.data.forEach(element => {
					this.volumeCommitmentResponds.push({
						Dealer_Id: element.dealerId,
						Model_Code: element.regionalAllocationVehicleLineCode,
						Vehicle_Line_Name: element.vehicleLineName,
						Production_Cycle: element.productionCycle,
						Wholesale_Month: element.wholesaleMonth,
						Volume_Commitments: element.allocationVehicleVolumeCommitCount,
						Create_User_Id: element.createUserId,
						Create_Timestamp_GMT: element.createTimestamp.split('+')[0].replace('T', ' '),
						Update_User_Id: element.updateUserId,
						Update_Timestamp_GMT: element.updateTimestamp.split('+')[0].replace('T', ' '),
					});
				});
			});
	}

	/*public getAuthToken() {
		this.wholesaleAPIAuthService.getAuthorisationData().subscribe(
			(response: Authorisation) => {
				this.authToken = response.access_token;
				sessionStorage.setItem('authToken', this.authToken);
			}
		);
	}*/

}
