import {Injectable} from '@angular/core';

@Injectable()
export class UserService {
	private isAuthorizedUser = false;
	private isZoneManger = false;
	public isDealer = false;
	private isRussiaDealer = false;
	private isDealerGroup = false;

	isAuthorisedUser(): boolean {

		if (Number(sessionStorage.getItem('roleCode')) === 1
			|| Number(sessionStorage.getItem('roleCode')) === 2
			|| Number(sessionStorage.getItem('roleCode')) === 3
			|| Number(sessionStorage.getItem('roleCode')) === 4
			|| Number(sessionStorage.getItem('roleCode')) === 5) {
			this.isAuthorizedUser = true;
		}
		return this.isAuthorizedUser;
	}

	canViewZoneCommitment(): boolean {
		if (Number(sessionStorage.getItem('roleCode')) === 1
			|| Number(sessionStorage.getItem('roleCode')) === 4
			|| Number(sessionStorage.getItem('roleCode')) === 5) {
			this.isZoneManger = true;
		}
		return this.isZoneManger;

	}

	canViewDealerCommitment(): boolean {
		if (Number(sessionStorage.getItem('roleCode')) === 2) {
			this.isDealer = true;
		}
		return this.isDealer;
	}

	canViewDealerGroupCommitment(): boolean {
		if (Number(sessionStorage.getItem('roleCode')) === 3) {
			this.isDealerGroup = true;
		}
		return this.isDealerGroup;
	}

	canViewDealerCommitmentHistory(): boolean {
		if ((Number(sessionStorage.getItem('roleCode')) === 2
				&& sessionStorage.getItem('loggedInUserMarketCode') !== 'WSPA2')
			|| Number(sessionStorage.getItem('roleCode')) === 3) {
			this.isDealer = true;
		}

		return this.isDealer;
	}

	canViewBulkOrderCreation(): boolean {
		if (Number(sessionStorage.getItem('roleCode')) === 2 &&
			sessionStorage.getItem('loggedInUserMarketCode') === 'WSPA2') {
			this.isDealer = true;
			this.isRussiaDealer = true;
		}
		return this.isRussiaDealer;
	}
}
