export const WholesaleConstants = {
	modelYear: '2019',
	vehicleLine: 'CR6',
	marketName: 'WAEGB',
	dealerType: 'PNACODE',
	districtsDropdownJsonPath: 'assets/data/districts.json',
	wholesaleConfirmMsgPrefix: 'Are you sure you want to ',
	wholesaleConfirmMsgSuffix: ' the Wholesale ?',
	wholesaleStatusLabel: 'Wholesale Status',
	commitStatusMsg: 'Wholesale is Closed',
	commitStatusRoleBasedMsg: 'Commit Restricted',
	dateFormatMMMYYYY: 'MMM-yyyy',
	dateFormatMMMMYYYY: 'MMMM-yyyy',
	dateFormatYYYYMM: 'yyyyMM',
	commitSuccessMessage: 'Volume Committed Successfully',
	commitFailureMessage: 'Volume Commitment Failed',
	noChangesMessage: 'No changes detected in Commitment',
	selectVehicleLineFirst: 'Please select a vehicle line to proceed',
	success: 'success',
	warn: 'warn',
	error: 'error',
	getVehicleLineUrl: '/web/v1/get-vehicle-lines',
	getSpecificationByMarketUrl: '/web/v1/markets/',
	saveSaveDealerMixUrl: '/web/v1/markets/',
	dealermixsuccessfullyUpdated: 'Dealer Mix Volume sucessfully Updated',
	noDealerMixAvailable: 'Please provide dealer Mix volume to confirm the mix',
	noChangesMixMessage: 'No changes detected in Dealer Mix Volume',
	noSpecificationsMappedForGivenVehicleLine: 'No Specifications mapped for the given vehicle line',
	noCommitmentForGivenVehicleLine: 'No commitments for the given vehicle line',
	entityMixTotalErrorMessage: 'Entity Mix Total is not equal to Commitment',
	CommitmentNotApproved: 'Commitments are yet to be Approved',
	districtDetailsForCV: {'districtCode': 'CV', 'districtName': 'CV'},
	getApsRolesUrl: '/web/v1/aps/roles'
}
