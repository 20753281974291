import {Component, EventEmitter, HostListener, Output} from '@angular/core';
import {UserService} from '../service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {MenuItem} from 'primeng/api';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css']
})
export class NavComponent {

	public sidebarVisible: boolean;
	@Output() closeClicked = new EventEmitter<boolean>();
	navMenuItems: MenuItem[] = [];

	constructor(private userService: UserService, private translate: TranslateService) {
		this.translate.onLangChange.subscribe(() => {
			this.navMenuItems = [
				{
					label: translate.instant('Home'),
					routerLink: '/home-page',
					command: this.closeHamMenu
				}
			];
			if (sessionStorage.getItem('cdsId') !== null) {
				if (userService.canViewZoneCommitment()) {
					this.navMenuItems.push({
						label: translate.instant('Wholesale Commitment'),
						routerLink: '/wholesale-by-zone',
						command: this.closeHamMenu,
					});
				}
				if (userService.canViewDealerCommitment()) {
					this.navMenuItems.push({
						label: translate.instant('Dealer Commitment'),
						routerLink: '/wholesaleDealerCommitment',
						command: this.closeHamMenu,
					});
				}
				if (userService.canViewDealerGroupCommitment()) {
					this.navMenuItems.push({
						label: translate.instant('Dealer Commitment'),
						routerLink: '/wholesaleDealerCommitment',
						command: this.closeHamMenu,
					});
				}

				if (userService.canViewDealerCommitmentHistory()) {
					this.navMenuItems.push({
						label: translate.instant('Commitment History'),
						routerLink: '/WholesaleDealerCommitmentHistory',
						command: this.closeHamMenu,
					});
				}

				if (userService.canViewBulkOrderCreation()) {
					this.navMenuItems.push({
						label: translate.instant('Dealer Entity Mix'),
						routerLink: '/dealer-mix-volume',
						command: this.closeHamMenu
					});
				}
				if (userService.canViewZoneCommitment()) {
					this.navMenuItems.push({
						label: translate.instant('Commitment Audit Trail'),
						routerLink: '/CommitmentAudit',
						command: this.closeHamMenu,
					});
				}

			}
		});
	}

	closeHamMenu = () => {
		this.closeClicked.emit(false);
	}

	/** Listener to toggle sidebar to hidden when it is not displayed */
	@HostListener('window:resize', []) onResize() {
		if (this.sidebarVisible === true) {
			const sideBar = document.getElementById('app-sidebar');
			if (window.getComputedStyle(sideBar).display === 'none') {
				this.sidebarVisible = false;
			}
		}
	}

	/** toggles visibility of sidebar to true */
	showSidebar = () => {
		this.sidebarVisible = true;
	}
}
