import {DealerGroupMixRequest} from './dealer-group-mix-request';

export class DealerGroupMixRequestWrapper {
	userId: string;
	dealerGroupMixRequestList: DealerGroupMixRequest[];
	marketKey: string;
  	vehicleLineCode: string;
  	wholesaleMonth: number;
  	productionMonth: string;
}
