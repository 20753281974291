import {Component, OnInit} from '@angular/core';
import {WholesaleService} from '../service/wholesale.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {formatDate} from '@angular/common';
import {UserService} from '../service/user.service';
import {WholesaleConstants} from '../constants/wholesale.constants';
import {WholesaleAPIAuthService} from '../service/wholesale-commitment-api-auth.service';
import {Authorisation} from '../models/authorisation.model';
import {WholesaleMessageService} from '../service/wholesale-message.service';
import {WholesaleResponse} from '../models/wholesale-response.model';
import {HttpErrorInterceptor} from '../service/http-error.interceptor';
import {ProductionOfferResponse} from '../models/production-offer-response.model';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup} from '@angular/forms';
import {DealerResponse} from '../models/dealer-details';
import * as  deliverViewListJson from 'src/assets/data/dealerViewList.json';

@Component({
	selector: 'app-wholesale-dealer-commitment-history.component',
	templateUrl: './wholesale-dealer-commitment-history.component.html',
	styleUrls: ['./wholesale-dealer-commitment-history.component.css']
})

export class WholesaleDealerCommitmentHistory implements OnInit {

	public formWholesale: FormGroup;
	public wholesaleCommitmentResponseList;
	public isAuthorizedUser = false;
	public systemMonth: any;
	public selectedMonth: any;
	public dealerId: string;
	public dealerName: string;
	public showVehiclesTable = false;
	public wholesaleStatus = false;
	public isNoRecordFound = false;
	public isServerError = false;
	public showDealerDetailDiv = false;
	public errorMsg: string;
	public isDealerGroupDetailDiv = false;
	public showVehiclesTreeTable = false;
	public isDealer = false;
	public isDealerGroup = false;
	public siteCode: string;
	public isFranceMarket = false;
	public wholesaleLast12Months = [];
	public selectKeyword: any;
	public vehicleType: any;
	public canViewDealerGroup = false;
	public wholesaleCurrentMonthAndYear: any;
	private authToken: string;
	public wholesaleDealerViewData: string;
	private dealerViewList: any[];

	constructor(private router: Router, private spinner: NgxSpinnerService,
				private wholesaleService: WholesaleService, private userService: UserService,
				private wholesaleAPIAuthService: WholesaleAPIAuthService,
				private wholesaleMessageService: WholesaleMessageService,
				private httpInterceptorService: HttpErrorInterceptor,
				private translate: TranslateService) {
	}

	ngOnInit() {
		// this.getAuthToken();
		this.isDealer = this.userService.canViewDealerCommitment();
		this.isDealerGroup = this.userService.canViewDealerGroupCommitment();
		this.isAuthorizedUser = this.userService.isAuthorisedUser();
		this.dealerViewList = (deliverViewListJson as any).default;
		this.wholesaleCommitmentResponseList = [];
		this.registerErrorHandler();
		this.systemMonth = new Date().toLocaleString('en-us', {month: 'long'});
		this.getWholesaleStatus();
		this.showVehiclesTable = false;
		this.showVehiclesTreeTable = false;
		this.siteCode = sessionStorage.getItem('loggedInSiteCode');
		this.translate.get('--SELECT--').subscribe((dataTranslated: string) => {
			this.selectKeyword = dataTranslated;
		});
		var monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
			'July', 'August', 'September', 'October', 'November', 'December'
		];
		var today = new Date();
		for (var i = 0; i < 12; i++) {

			this.translate.get(monthNames[parseInt(today.getMonth() + '')]).subscribe((translateMonth: string) => {
				this.wholesaleLast12Months.push({
					value: today.getFullYear() + '' + String(today.getMonth() + 1).padStart(2, '0'),
					label: translateMonth + '-' + today.getFullYear()
				});
			});
			today.setMonth(today.getMonth() - 1);

		}
		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WAEFX') {
			this.isFranceMarket = true;
		}
	}

	isVehicleLineTypeFirstIndexMatch(dealerRowData: WholesaleResponse, inputRowIndex: number): boolean {
		const foundRowIndex = this.wholesaleCommitmentResponseList.findIndex(dealer => dealer.vehicleLineType === dealerRowData.vehicleLineType);
		const isIndexMatching = foundRowIndex === inputRowIndex;
		if (isIndexMatching) {
			if (dealerRowData.vehicleLineType === 'CR') {
				this.vehicleType = 'CAR';
			} else {
				this.vehicleType = 'CV';
			}
		}
		return isIndexMatching;
	}

	public isRowVehicleLineFirstIndexMatch(dealerRowData: WholesaleResponse, inputRowIndex: number): boolean {
		const foundRowIndex = this.wholesaleCommitmentResponseList.findIndex(dealer => (dealer.regionalAllocationVLCode === dealerRowData.regionalAllocationVLCode
			&& dealer.vehicleLineAndRegionalVehicleLineCode === dealerRowData.vehicleLineAndRegionalVehicleLineCode));
		return foundRowIndex === inputRowIndex;
	}

	public transformDate(date: string | number | Date, pattern: string) {
		return formatDate(date, pattern, 'en-US');
	}

	public onWholesaleMonthSelect(month: any) {
		this.selectedMonth = month;
		this.spinner.show();
		this.isNoRecordFound = false;
		this.showVehiclesTable = false;
		this.showVehiclesTreeTable = false;
		this.isServerError = false;
		this.wholesaleCurrentMonthAndYear = month;
		this.getWholesaleDealerDetail(this.siteCode);

	}

	updateAmendedAllocation(wholesaleList: WholesaleResponse[]) {
		wholesaleList.forEach(wholesaleDetail => {
			wholesaleDetail.productionMonthAndYear = this.transformDate(wholesaleDetail.productionMonth.toString().substr(0, 4) + '-' + wholesaleDetail.productionMonth.toString().substr(4), WholesaleConstants.dateFormatMMMMYYYY);
			if (wholesaleDetail.vehicleLineAndRegionalVehicleLineCode === 'GCR') {
				wholesaleDetail.vehicleLineName = 'P375 Ranger ICA ';
			}
			this.wholesaleCommitmentResponseList.push(wholesaleDetail);
		});
	}

	/*public getAuthToken() {
		this.wholesaleAPIAuthService.getAuthorisationData().subscribe(
			(response: Authorisation) => {
				this.authToken = response.access_token;
				sessionStorage.setItem('authToken', this.authToken);
			}
		);
	}*/

	private registerErrorHandler() {
		this.httpInterceptorService.errorStream$().subscribe(errorObj => {
			const url: string = errorObj.url;
			if (!url.includes('WholesaleCommitmentService')) {
				const response = this.isJson(errorObj.error) ? JSON.parse(errorObj.error) : errorObj.error;
				this.isServerError = true;
				let errMessage;
				if (response instanceof ArrayBuffer) {
					errMessage = response;
				} else if (this.isJson(response.error.messages[0])) {
					errMessage = JSON.parse(response.error.messages[0]);
				} else {
					errMessage = response.error.messages[0];
				}

				if (errMessage.error) {
					this.errorMsg = errMessage.error;
				} else {
					this.errorMsg = errorObj.statusText;
				}
			} else {
				this.isServerError = true;
				if (errorObj.error.error) {
					this.errorMsg = errorObj.error.error;
				} else {
					this.errorMsg = errorObj.statusText;
				}
			}
			this.spinner.hide();
		});
	}

	public getWholesaleDealerDetail(dealerId: string) {
		if (this.isDealerGroup) {
			this.wholesaleService.getDealerDetails(null, null, dealerId, null).subscribe((
				dealerResponse: DealerResponse[]) => {
				this.wholesaleService.getProductionOffersFromDealerRequest(dealerResponse, this.wholesaleCurrentMonthAndYear)
					.subscribe((response: ProductionOfferResponse[]) => {
						this.wholesaleService.getWholesaleDetail(response, this.wholesaleCurrentMonthAndYear).subscribe((response: WholesaleResponse[]) => {
							this.getWholesaleResponseByDealer(response, dealerId);
						});
					});
			});
		} else {
			this.wholesaleService.getDealerDetails(null, null, null, dealerId).subscribe((
				dealerResponse: DealerResponse[]) => {
				this.wholesaleService.getProductionOffersFromDealerRequest(dealerResponse, this.wholesaleCurrentMonthAndYear)
					.subscribe((response: ProductionOfferResponse[]) => {
						this.wholesaleService.getWholesaleDetail(response, this.wholesaleCurrentMonthAndYear).subscribe((response: WholesaleResponse[]) => {
							this.getWholesaleResponseByDealer(response, dealerId);
						});
					});
			});
		}
	}

	public getWholesaleResponseByDealer(response: WholesaleResponse[], dealerId: string) {
		if (response.length > 0) {
			this.showVehiclesTable = true;
			this.wholesaleCommitmentResponseList = [];
			this.updateAmendedAllocation(response);
			this.wholesaleDealerViewData = JSON.stringify(response);
			this.dealerId = this.wholesaleCommitmentResponseList[0].dealerId;
			this.dealerName = this.wholesaleCommitmentResponseList[0].dealerName;
		} else {
			this.dealerId = dealerId;
			this.dealerName = '-';
			this.isNoRecordFound = true;
		}
		if (this.isDealer) {
			this.spinner.hide();
			this.showDealerDetailDiv = true;
			this.isDealerGroupDetailDiv = false;
		}
		if (this.isDealerGroup && !this.isNoRecordFound) {
			this.spinner.hide();
			this.showDealerDetailDiv = true;
			this.isDealerGroupDetailDiv = true;
			this.showVehiclesTreeTable = true;
			this.showVehiclesTable = false;
		}
	}

	public getWholesaleStatus() {
		this.wholesaleService.getWholesaleStatus().subscribe(response => {
			this.wholesaleStatus = response.wholesaleStatus;
		});
	}

	private isJson(req): boolean {
		try {
			JSON.parse(req);
			return true;
		} catch (err) {
			return false;
		}
	}
}
