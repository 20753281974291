import {Component, Input, OnInit, Output} from '@angular/core';
import {WholesaleResponse} from '../models/wholesale-response.model';

@Component({
	selector: 'wholesale-tree',
	templateUrl: './wholesale-tree.component.html',
	styleUrls: ['./wholesale-tree.component.css']
})
export class WholesaleTreeComponent implements OnInit {

	public isCopyAllChecked: boolean;
	public ifVehicleLineExpanded = true;
	@Input() @Output() wholesaleCommitmentResponseList: WholesaleResponse[];
	@Input() @Output() isDealerGroupHistory: boolean;
	public vehicleType: any;
	public canViewDealerGroup: boolean;
	public expandedVehicleSize: any;
	public vehicleLineTotal: any = {};
	public vehicleLineTotalList: any = [];

	ngOnInit() {
		this.calculateTotalByVehicleLine();
		if (sessionStorage.getItem('loggedInUserMarketCode') === 'WAEGB') {
			this.canViewDealerGroup = true;
		} else {
			this.canViewDealerGroup = false;
		}
	}

	public checkIfVehicleLineSelectedUnderTDtag() {
		const expandedVehicles = document.getElementsByClassName('fa fa-fw fa-chevron-circle-down');
		if ((this.expandedVehicleSize === 0 && expandedVehicles.length === 1) || (this.expandedVehicleSize === 1 && expandedVehicles.length === 0)) {
			this.ifVehicleLineExpanded = !this.ifVehicleLineExpanded;
			this.expandedVehicleSize = null;
		}
	}

	public checkIfVehicleLineSelectedUnderAtag() {
		const expandedVehicles = document.getElementsByClassName('fa fa-fw fa-chevron-circle-down');
		this.expandedVehicleSize = expandedVehicles.length;
	}

	public handleSelectAll() {
		const collapsedVehicles = document.getElementsByClassName('fa fa-fw fa-chevron-circle-right');
		const expandedVehicles = document.getElementsByClassName('fa fa-fw fa-chevron-circle-down');
		if (this.isCopyAllChecked) {
			if (expandedVehicles.length === 0) {
				this.ifVehicleLineExpanded = !this.ifVehicleLineExpanded;
			}
			if (collapsedVehicles.length >= 0) {
				this.expandOrCollapse(collapsedVehicles);
			}
		}
		this.selectOrUnselectAll();
	}

	public checkIfAllSelected() {
		this.isCopyAllChecked = this.wholesaleCommitmentResponseList.every(function (item: WholesaleResponse) {
			return item.isCheckBoxSelected === true;
		});
	}

	public copyProdOfferToCommitment(event) {
		this.wholesaleCommitmentResponseList.forEach(wholesaleResponse => {
			if (wholesaleResponse.isCheckBoxSelected) {
				wholesaleResponse.allocationVehicleVolumeCommitCount = wholesaleResponse.productionOffer;
			}
		});
		this.refreshDataGridOnClick();
	}

	refreshDataGrid(volumeCommitment: number, rowdata: WholesaleResponse) {
		rowdata.allocationVehicleVolumeCommitCount = Number(volumeCommitment);
		this.refreshDataGridOnClick();
	}

	public onExpandOrCollapseAllLink() {
		const collapsedVehicles = document.getElementsByClassName('fa fa-fw fa-chevron-circle-right');
		const expandedVehicles = document.getElementsByClassName('fa fa-fw fa-chevron-circle-down');
		this.showVehicleLineInHeader();
		if (collapsedVehicles.length >= 1) {
			this.expandOrCollapse(collapsedVehicles);
		} else {
			this.expandOrCollapse(expandedVehicles);
			this.ifVehicleLineExpanded = !this.ifVehicleLineExpanded;
		}
	}

	public isRowVehicleLineFirstIndexMatch(dealerRowData: WholesaleResponse, inputRowIndex: number): boolean {

		const foundRowIndex = this.wholesaleCommitmentResponseList.findIndex(dealer => (dealer.regionalAllocationVLCode === dealerRowData.regionalAllocationVLCode
			&& dealer.vehicleLineAndRegionalVehicleLineCode === dealerRowData.vehicleLineAndRegionalVehicleLineCode));
		const isIndexMatching = foundRowIndex === inputRowIndex;
		if (isIndexMatching) {
			this.vehicleLineTotalList.forEach(vehicleLine => {
				if (dealerRowData.vehicleLineAndRegionalVehicleLineCode === vehicleLine.vehicleLineCode) {
					dealerRowData.allocationTotal = vehicleLine.allocationTotal;
					dealerRowData.commitmentTotal = vehicleLine.commitmentTotal;
					dealerRowData.overAndUnderTotal = vehicleLine.overAndUnderTotal;
				}
			});
		}
		return isIndexMatching;
	}

	public isVehicleLineTypeFirstIndexMatch(dealerRowData: WholesaleResponse, inputRowIndex: number): boolean {
		const foundRowIndex = this.wholesaleCommitmentResponseList.findIndex(dealer => dealer.vehicleLineType === dealerRowData.vehicleLineType);
		const isIndexMatching = foundRowIndex === inputRowIndex;
		if (isIndexMatching) {
			if (dealerRowData.vehicleLineType === 'CR') {
				this.vehicleType = 'CAR';
			} else {
				this.vehicleType = 'CV';
			}
		}
		return isIndexMatching;
	}

	public getRowProductionMonthIndex(dealerRowData: WholesaleResponse): number {
		return this.wholesaleCommitmentResponseList.findIndex(dealer => dealer.productionMonth === dealerRowData.productionMonth
			&& dealer.regionalAllocationVLCode === dealerRowData.regionalAllocationVLCode
			&& dealer.vehicleLineAndRegionalVehicleLineCode === dealerRowData.vehicleLineAndRegionalVehicleLineCode);
	}

	public getRowProductionMonthSpan(dealerRowData: WholesaleResponse): number {
		return this.wholesaleCommitmentResponseList.filter(dealer => dealer.productionMonth === dealerRowData.productionMonth
			&& dealer.regionalAllocationVLCode === dealerRowData.regionalAllocationVLCode
			&& dealer.vehicleLineAndRegionalVehicleLineCode === dealerRowData.vehicleLineAndRegionalVehicleLineCode).length;
	}

	public getRowDealerGroupIndex(dealerRowData: WholesaleResponse): number {
		return this.wholesaleCommitmentResponseList.findIndex(dealer => dealer.dealerGroupName === dealerRowData.dealerGroupName && dealer.productionMonth === dealerRowData.productionMonth
			&& dealer.regionalAllocationVLCode === dealerRowData.regionalAllocationVLCode
			&& dealer.vehicleLineAndRegionalVehicleLineCode === dealerRowData.vehicleLineAndRegionalVehicleLineCode);
	}

	public getRowDealerGroupSpan(dealerRowData: WholesaleResponse): number {
		return this.wholesaleCommitmentResponseList.filter(dealer => dealer.dealerGroupName === dealerRowData.dealerGroupName && dealer.productionMonth === dealerRowData.productionMonth
			&& dealer.regionalAllocationVLCode === dealerRowData.regionalAllocationVLCode
			&& dealer.vehicleLineAndRegionalVehicleLineCode === dealerRowData.vehicleLineAndRegionalVehicleLineCode).length;
	}

	public calculateTotalByVehicleLine() {
		this.vehicleLineTotal = {};
		this.wholesaleCommitmentResponseList.forEach(record => {
			if (!(record.vehicleLineAndRegionalVehicleLineCode in this.vehicleLineTotal)) {
				this.vehicleLineTotal[record.vehicleLineAndRegionalVehicleLineCode] = {
					'vehicleLineCode': record.vehicleLineAndRegionalVehicleLineCode,
					'allocationTotal': 0,
					'commitmentTotal': 0,
					'overAndUnderTotal': 0
				};
			}
			this.vehicleLineTotal[record.vehicleLineAndRegionalVehicleLineCode]['allocationTotal'] += record.productionOffer;
			this.vehicleLineTotal[record.vehicleLineAndRegionalVehicleLineCode]['commitmentTotal'] += record.allocationVehicleVolumeCommitCount;
			this.vehicleLineTotal[record.vehicleLineAndRegionalVehicleLineCode]['overAndUnderTotal'] += record.allocationVehicleVolumeCommitCount - record.productionOffer;
		});
		this.vehicleLineTotalList = this.toJsonArrayOfValueObjects(this.vehicleLineTotal);
	}

	public showVehicleLineInHeader() {
		if (this.expandedVehicleSize === 0) {
			this.ifVehicleLineExpanded = !this.ifVehicleLineExpanded;
			this.expandedVehicleSize = null;
		}
	}

	private selectOrUnselectAll() {
		this.wholesaleCommitmentResponseList.forEach(element => {
			element.isCheckBoxSelected = this.isCopyAllChecked;
		});
	}

	public refreshDataGridOnClick() {
		this.calculateTotalByVehicleLine();
	}

	private toJsonArrayOfValueObjects(jsonObj: any) {
		const jsonArray = [];
		Object.keys(jsonObj).forEach(key => {
			if (typeof jsonObj[key] === 'object') {
				jsonArray.push(jsonObj[key]);
			}
		});
		return jsonArray;
	}

	private expandOrCollapse(vehicles: HTMLCollectionOf<Element>) {
		for (let i = 0; i < vehicles.length; i++) {
			const element: HTMLElement = vehicles[i] as HTMLElement;
			element.click();
		}
	}

}
